import styled from "styled-components";
import { APPPROFILE } from "../../../constants";
import { Button } from "@mui/material";

export const StyledFooter = styled.div(({ height }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  height: height,
  padding: 0,
  margin: 0,
}));

export const StyledCartButton = styled(Button)(({ fontSize, background }) => ({
  flexGrow: 1,
  background: background ? background : `${APPPROFILE.ACCENTCOLOR}`,
  borderRadius: 0,
  width: "calc(100vw / 3)",
  textAlign: "left",
  fontSize: fontSize,
  maxWidth: "400px",
  margin: 0,
  color: "white",
  "&:hover, &.Mui-focusVisible": {
    background: `${APPPROFILE.ACCENTCOLOR}`,
  },
  svg: {
    fontSize: `${fontSize} !important`,
  },
}));

export const StyledCartSummary = styled.div(({ fontSize, height }) => ({
  flexGrow: 1,
  margin: 0,
  background: `${APPPROFILE.PROFILECOLOR}`,
  width: "calc(100vw / 3)",
  padding: "15px 25px",
  p: {
    color: "white",
    fontSize: fontSize,
    lineHeight: `calc(${height} - 30px)`,
    textAlign: "center",
  },
}));

export const StyledCartInfo = styled.div(() => ({
  position: "fixed",
  background: "#2e7d32",
  marginLeft: "25px",
  height: "135px",
  borderRadius: "5px 5px 0px 0px",
  transition: "bottom 0.3s ease-in-out",
  color: "white",
  h5: {
    fontSize: "1.4em",
    lineHeight: "75px",
    textTransform: "uppercase",
    fontWeight: "bold",
    paddingBottom: "2px",
  },
  p: {
    lineHeight: "unset",
    fontSize: "1em",
    margin: "0px 10px",
    padding: 0,
  },
}));
