import Header from "./Header";
import Footer from "./footer/Footer";
import useHardwareFormFactor from "../../hooks/useHardwareFormFactor";
import BasketFooter from "./footer/BasketFooter";
import BackgropLoader from "../common/BackdropLoader";
import ClickGuard from "../common/ClickGuard";
import CheckoutFooter from "./footer/CheckoutFooter";
import { Alert, AlertTitle, Dialog, DialogContent, Grid } from "@mui/material";
import useOrderProcessor from "../../hooks/useOrderProcessor";
import { getBasketCount } from "../../redux/basketSlice";
import { useSelector } from "react-redux";

const Frame = ({
  disableHeader,
  disableFooter,
  useBasketFooter,
  useCheckoutFooter,
  enablePadding,
  enableClickGuard,
  children,
  loading,
}) => {
  const count = useSelector(getBasketCount);
  const { processingOrder, processingOrderError } = useOrderProcessor();
  const { frameConfig } = useHardwareFormFactor();
  const {
    headerHeight,
    extendedFooterHeight,
    defaultFooterHeight,
    framePadding,
  } = frameConfig;

  const currentFooterHeight =
    count > 0 && (useCheckoutFooter || useBasketFooter)
      ? extendedFooterHeight
      : defaultFooterHeight;

  const renderFooter = () => {
    if (useBasketFooter && count > 0) {
      return <BasketFooter />;
    } else if (useCheckoutFooter && count > 0) {
      return <CheckoutFooter />;
    }

    return <Footer />;
  };

  const visiblOrderErrorDialog = processingOrderError
    ? `${processingOrderError}`.length > 0
    : false;

  return (
    <ClickGuard enable={enableClickGuard}>
      <BackgropLoader open={loading || processingOrder} />

      <Dialog
        open={visiblOrderErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogContent>
          <Alert severity="error" sx={{ height: "250px", width: "700px" }}>
            <AlertTitle>Error</AlertTitle>
            <p>{processingOrderError}</p>
          </Alert>
        </DialogContent>
      </Dialog>

      <Grid container direction="row" spacing={0}>
        {!disableHeader && (
          <Grid item xs={12}>
            <Header />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{
            minHeight: `calc(100vh - ${headerHeight + currentFooterHeight}px)`,
            padding: enablePadding ? framePadding : undefined,
          }}
        >
          {children}
        </Grid>
        {!disableFooter && (
          <Grid item xs={12}>
            {renderFooter()}
          </Grid>
        )}
      </Grid>
    </ClickGuard>
  );
};

export default Frame;
