import { ReactComponent as BurgerIcon } from "./assets/food-icon/burger.svg";
import { ReactComponent as PizzaIcon } from "./assets/food-icon/pizza.svg";
import { ReactComponent as PizzaekspertenIcon } from "./assets/food-icon/pizzaeksperten.svg";
import { ReactComponent as SausageSvg } from "./assets/food-icon/sausage.svg";
import { ReactComponent as HotFoodIcon } from "./assets/food-icon/hotfood.svg";
import { ReactComponent as OtherIcon } from "./assets/food-icon/other.svg";
import { ReactComponent as SaladIcon } from "./assets/food-icon/salad.svg";
import { ReactComponent as DrinkIcon } from "./assets/food-icon/drink.svg";
import { ReactComponent as KioskIcon } from "./assets/food-icon/kiosk.svg";
import { ReactComponent as TobaccoIcon } from "./assets/food-icon/tobacco.svg";
import { ReactComponent as CarIcon } from "./assets/food-icon/car.svg";
import StarBorderIcon from "@mui/icons-material/StarBorder";

export const VAT = {
  EATIN: 25,
  TAKEOUT: 15,
  NO_FOOD: 25,
  FOOD_GROCERIES: 15,
};

export const SCREEN_TIMEOUT = 300;
export const SCREEN_TIMEOUT_COUNTDOWN = 30;
export const SCREEN_TIMEOUT_ENABLED = true;
export const SCREENSAVER_INTERVAL_SECONDS = 15;
export const SCREENSAVER_RELOAD_SECONDS = 600;

export const TEXTS = {
  EATIN: "Spis her",
  TAKEOUT: "Ta med",
};

export const PROGRAM_OWNER = {
  title: window.ToppBonusConfig.program.title,
  address: window.ToppBonusConfig.program.address,
  phone: window.ToppBonusConfig.program.phone,
  email: window.ToppBonusConfig.program.email,
  logo: window.ToppBonusConfig.program.logo,
};

export const isDebug = () => window.ToppBonusConfig.debug === true;

export const ENDPOINTS = {
  base: () => window.ToppBonusConfig.endpoints.api,
  image: (id) => `${window.ToppBonusConfig.endpoints.image}${id}`,
};

export const APPPROFILE = {
  PROFILECOLOR: window.ToppBonusConfig.profile.profilecolor || "#393E41",
  ACCENTCOLOR: window.ToppBonusConfig.profile.accentcolor || "#E94F37",
  LOGO: window.ToppBonusConfig.profile.logo || "/images/demo-logo.png",
};

export const REDUX_DATA_STATUS = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  NOT_FOUND: "NOT_FOUND",
};

export const MENYVELGEREN_MODULES = {
  BASKET: () => "/basket",
  CHECKOUT: () => "/checkout",
  MENU: () => "/menyvelgeren",
  MENUGROUP: (group) => `/menyvelgeren/group/${group}`,
  PRODUCTLIST: (mid) => `/menyvelgeren/menu/${mid}`,
  PRODUCTPAGE: (pid) => `/menyvelgeren/product/${pid}`,
};

export const MENYVELGER_MENUGROUP = {
  JULEKALENDER: {
    title: "Julekalender",
    sortkey: 10,
    image: "/images/food-icon/julekalender.png",
    useImage: true,
  },
  PROMOTION: {
    title: "Kampanje",
    sortkey: 11,
    icon: <StarBorderIcon />,
  },
  PIZZAEKSPERTEN: {
    title: "Pizzaeksperten",
    sortkey: 20,
    icon: <PizzaekspertenIcon />,
  },
  PIZZA: {
    title: "Pizza",
    sortkey: 30,
    icon: <PizzaIcon />,
  },
  BURGER: {
    title: "Burger",
    sortkey: 40,
    icon: <BurgerIcon />,
  },
  SAUSAGE: {
    title: "Pølse",
    sortkey: 50,
    icon: <SausageSvg />,
  },
  SALAD: {
    title: "Salater, påsmurt og panini",
    sortkey: 70,
    icon: <SaladIcon />,
  },
  OTHER: {
    title: "Andre retter",
    sortkey: 80,
    icon: <OtherIcon />,
  },
  DRINK: {
    title: "Drikke",
    sortkey: 90,
    icon: <DrinkIcon />,
  },
  KIOSK: {
    title: "Kiosk og dagligvare",
    sortkey: 100,
    icon: <KioskIcon />,
  },
  TOBACCO: {
    title: "Tobakk",
    sortkey: 110,
    icon: <TobaccoIcon />,
  },
  CAR: {
    title: "Bil, hjem og fritid",
    sortkey: 120,
    icon: <CarIcon />,
  },
  TUNA: {
    title: "Halal Tuna",
    sortkey: 130,
    useImage: true,
    image: "/images/food-icon/halaltuna.png",
  },

  // TO BE DELETED
  HOTFOOD: {
    title: "Varmmat",
    sortkey: 60,
    icon: <HotFoodIcon />,
  },
};
