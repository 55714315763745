import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REDUX_DATA_STATUS } from "../constants";
import { SERVICES } from "../services";
import { sleep } from "../components/utils/sleep";

export const loadStoreData = createAsyncThunk(
  "storedata/loadStoreData",
  async (machineKey) => {
    if (machineKey) {
      for (let i = 0; i < 5; i++) {
        try {
          const r = await SERVICES.fetchAgreementData(machineKey);
          if (r.ok) {
            const data = await r.json();
            return data;
          } else {
            await sleep(i);
          }
        } catch (e) {
          await sleep(i);
        }
      }

      window.location.reload();
    }
    return false;
  }
);

export const loadExtraOptions = createAsyncThunk(
  "storedata/loadExtraOptions",
  async (machineKey) => {
    if (machineKey) {
      for (let i = 0; i < 5; i++) {
        try {
          const r = await SERVICES.fetchExtraOptions(machineKey);
          if (r.ok) {
            const data = await r.json();
            return data;
          } else {
            await sleep(i);
          }
        } catch (e) {
          await sleep(i);
        }
      }

      window.location.reload();
    }
    return false;
  }
);

export const storeDataSlice = createSlice({
  name: "storedata",
  initialState: {
    agreement: null,
    agreementstatus: REDUX_DATA_STATUS.IDLE,
    extraoptions: null,
    extraoptionsstatus: REDUX_DATA_STATUS.IDLE,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadStoreData.pending, (state) => {
        state.status = REDUX_DATA_STATUS.LOADING;
      })
      .addCase(loadStoreData.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = REDUX_DATA_STATUS.IDLE;
          const newData = action.payload ? action.payload : null;
          newData.accounting_enabled = newData.accounting_enabled === "1";
          newData.forward_orders_toppbonus =
            newData.forward_orders_toppbonus === "1";
          newData.ispreview = newData.ispreview === "1";
          newData.hasextras = newData.hasextras === "1";
          newData.hasextrasqty = newData.hasextrasqty === "1";
          newData.hasinprice = newData.hasinprice === "1";
          state.agreement = newData;
        } else {
          state.status = REDUX_DATA_STATUS.NOT_FOUND;
          state.agreement = null;
        }
      })
      .addCase(loadExtraOptions.pending, (state) => {
        state.status = REDUX_DATA_STATUS.LOADING;
      })
      .addCase(loadExtraOptions.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = REDUX_DATA_STATUS.IDLE;
          state.extraoptions = action.payload ? action.payload : null;
        } else {
          state.status = REDUX_DATA_STATUS.NOT_FOUND;
          state.extraoptions = null;
        }
      });
  },
});

export const selectStoreData = (state) => state.storeData?.agreement;

export const selectStoreHasInPrice = (state) =>
  state.storeData?.agreement?.hasinprice;

export const selectStoreShouldForwardOrder = (state) =>
  state.storeData?.agreement?.forward_orders_toppbonus;

export const selectStoreIsPreview = (state) =>
  state.storeData?.agreement?.ispreview;

export const selectExtraOptions = (state) => {
  if (state.storeData?.agreement?.hasextras)
    return state.storeData?.extraoptions;
  return undefined;
};

export const selectStoreDataLoading = (state) =>
  !(state.storeData?.agreementstatus !== REDUX_DATA_STATUS.LOADING);

export const selectExtraOptionsLoading = (state) =>
  !(state.storeData?.extraoptionsstatus !== REDUX_DATA_STATUS.LOADING);

export default storeDataSlice.reducer;
