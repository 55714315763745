import React from "react";
import ItemGrid from "../../components/common/ItemGrid";
import Frame from "../../components/frame/Frame";
import { MENYVELGEREN_MODULES } from "../../constants";
import { useSelector } from "react-redux";
import {
  selectMenyvelgerenLoading,
  selectMenyvelgerenMenus,
} from "../../redux/menyvelgerenDataSlice";
import { getMenuGroupById } from "../../services";

const MenuView = () => {
  const menuData = useSelector(selectMenyvelgerenMenus);
  const isMenuLoadingLoading = useSelector(selectMenyvelgerenLoading);
  const uniqueGroups = (menuData || []).map((p) => {
    const menugroup = getMenuGroupById(p.menugroup);

    return [
      p.menugroup,
      {
        text: menugroup.title,
        linkTo: MENYVELGEREN_MODULES.MENUGROUP(p.menugroup),
        image: menugroup.image,
        useImage: menugroup.useImage,
        useImageContain: true,
        icon: menugroup.icon,
        sortkey: menugroup.sortkey,
      },
    ];
  });

  const items = Object.values(Object.fromEntries(uniqueGroups)).sort(
    (a, b) => a.sortkey - b.sortkey
  );

  return (
    <Frame
      useBasketFooter={true}
      enableClickGuard={true}
      enablePadding={true}
      loading={isMenuLoadingLoading}
    >
      <ItemGrid items={items}></ItemGrid>
    </Frame>
  );
};

export default MenuView;
