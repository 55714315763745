const LOCAL_STORAGE_KEY = "receipt-number";

const useLocalReceiptCounter = () => {
  const getNextNumber = () => {
    try {
      const previous = parseInt(localStorage.getItem(LOCAL_STORAGE_KEY));
      const newNumber = previous + 1;
      if (newNumber >= 100 || newNumber <= 999) return newNumber;
    } catch {}
    return 100;
  };

  const setNumber = (n) => localStorage.setItem(LOCAL_STORAGE_KEY, n);

  return {
    getNextNumber: () => {
      const newNumber = getNextNumber();
      setNumber(newNumber);
      return newNumber;
    },
  };
};

export default useLocalReceiptCounter;
