import { createSlice } from "@reduxjs/toolkit";

export const processingOrderSlice = createSlice({
  name: "processingorder",
  initialState: {
    processingorder: false,
    processingerror: false,
  },
  reducers: {
    setProcessingOrder: (state, { type, payload }) => {
      state.processingorder = payload;
    },
    setProcessingOrderError: (state, { type, payload }) => {
      state.processingerror = payload;
    },
  },
});

export const { setProcessingOrder, setProcessingOrderError } =
  processingOrderSlice.actions;

export const selectProcessingOrder = (state) =>
  state.processingOrderData?.processingorder;

export const selectProcessingOrderError = (state) =>
  state.processingOrderData?.processingerror;

export default processingOrderSlice.reducer;
