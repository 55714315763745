import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import useHardwareFormFactor from "../../hooks/useHardwareFormFactor";
import MenyvelgerenPagination from "./MenyvelgerenPagination";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FORMATTER } from "../../services";
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { selectStoreHasInPrice } from "../../redux/storeDataSlice";
import { useSelector } from "react-redux";
import { TEXTS } from "../../constants";

const ENABLE_RIPPLE_ON_ROW = false;

const ClickableTableRow = ({ ripple, children, ...props }) => {
  if (ripple) {
    return (
      <ButtonBase
        component={TableRow}
        classes={{}}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          display: "table-row",
        }}
        {...props}
      >
        {children}
      </ButtonBase>
    );
  } else {
    return <TableRow {...props}>{children}</TableRow>;
  }
};

const ProductItemList = ({ products }) => {
  const navigate = useNavigate();
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningType, setShowWarningType] = useState("");
  const [showWarningSuccess, setShowWarningSuccess] = useState("");
  const { isNewFormFactor } = useHardwareFormFactor();
  const cellStyle = isNewFormFactor
    ? { fontSize: "1em", padding: "17px 10px" }
    : {};

  const handleConfirmWarning = (accept) => {
    setShowWarning(0);
    if (accept) {
      navigate(showWarningSuccess);
    }
  };

  const handleProductClick = (product) => {
    if (product.disabled === "1") return false;

    setTimeout(() => {
      setShowWarningSuccess(product.linkTo);
      if (product.tobacco_cigarettes === "1") {
        setShowWarning(1);
        setShowWarningType("tobacco_cigarettes");
      } else if (product.tobacco_snuff === "1") {
        setShowWarning(1);
        setShowWarningType("tobacco_snuff");
      } else {
        navigate(product.linkTo);
      }
    }, 100);
  };

  return (
    <>
      <Dialog
        open={showWarning > 0}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {showWarningType === "tobacco_snuff" && (
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                color: "black",
                fontSize: "18px",
                textAlign: "center",
                padding: "15px",
              }}
            >
              Denne tobakksvaren kan være helseskadelig og er
              avhengighetsskapende. Ikke for personer under 18 år.
            </DialogContentText>
          )}
          {showWarningType === "tobacco_cigarettes" && (
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                color: "black",
                fontSize: "18px",
                textAlign: "center",
                padding: "15px",
              }}
            >
              Røyking er svært skadelig for deg og dine omgivelser. Ikke for
              personer under 18 år.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ padding: "10px 35px" }}
            onClick={() => handleConfirmWarning(false)}
          >
            Avbryt
          </Button>
          <div style={{ flex: "1 0 0" }} />
          <Button
            variant="contained"
            disableElevation
            sx={{ padding: "10px 35px" }}
            onClick={() => handleConfirmWarning(true)}
            autoFocus
          >
            Fortsett &raquo;
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "50px" }}>&nbsp;</TableCell>
              <TableCell>Produkt</TableCell>
              <TableCell
                align="right"
                sx={{
                  whiteSpace: "nowrap",
                  display: {
                    xs: "none",
                    sm: hasInPrice ? "table-cell" : "none",
                  },
                }}
              >
                {TEXTS.EATIN}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  whiteSpace: "nowrap",
                  display: {
                    xs: "none",
                    sm: hasInPrice ? "table-cell" : "none",
                  },
                }}
              >
                {TEXTS.TAKEOUT}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  whiteSpace: "nowrap",
                  display: {
                    xs: "table-cell",
                    sm: hasInPrice ? "none" : "table-cell",
                  },
                }}
              >
                Pris
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(products || []).map((product, idx) => (
              <ClickableTableRow
                ripple={ENABLE_RIPPLE_ON_ROW}
                onClick={() => handleProductClick(product)}
                key={`row_${idx}`}
              >
                <TableCell>
                  {product.disabled !== "1" && (
                    <IconButton
                      aria-label="Legg til i handlekurv"
                      color="primary"
                    >
                      <AddCircleIcon />
                    </IconButton>
                  )}

                  {product.disabled === "1" && (
                    <IconButton
                      aria-label="Midlertidig utsolgt"
                      color="secondary"
                    >
                      <DoNotDisturbIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell component="th" scope="row" sx={cellStyle}>
                  <b>{product.title}</b>
                  <br />
                  {product.description}
                </TableCell>
                {product.disabled === "1" && (
                  <TableCell
                    colSpan={2}
                    align="right"
                    sx={{
                      display: {
                        xs: "none",
                        sm: hasInPrice ? "table-cell" : "none",
                      },
                      ...cellStyle,
                    }}
                  >
                    <Typography color="secondary">
                      Midlertidig utsolgt
                    </Typography>
                  </TableCell>
                )}

                {product.disabled !== "1" && (
                  <>
                    <TableCell
                      align="right"
                      sx={{
                        display: {
                          xs: "none",
                          sm: hasInPrice ? "table-cell" : "none",
                        },
                        ...cellStyle,
                      }}
                    >
                      {FORMATTER.formatCurrent(product.price_in)}
                    </TableCell>
                    <TableCell align="right" sx={cellStyle}>
                      {FORMATTER.formatCurrent(product.price_out)}
                    </TableCell>
                  </>
                )}
              </ClickableTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const ItemList = ({ items, onNextPageClick, onPreviousPageClick }) => {
  const [page, setPage] = useState(1);
  const { gridConfig } = useHardwareFormFactor();
  const pageItemStart = gridConfig.itemListMaxItemsPrPage * (page - 1);
  const totalPageCount = Math.ceil(
    (items || []).length / gridConfig.itemListMaxItemsPrPage
  );
  const visibleItems = [...(items || [])].slice(
    pageItemStart,
    pageItemStart + gridConfig.itemListMaxItemsPrPage
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      <Grid item sx={{ width: "100%" }}>
        <Grid
          sx={{
            width: "100%",
          }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          rowSpacing={2}
          columnSpacing={2}
        >
          <ProductItemList products={visibleItems}></ProductItemList>
        </Grid>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <MenyvelgerenPagination
          pageCount={totalPageCount}
          page={page}
          onChangePage={(e, page) => setPage(page)}
          onNextPageClick={onNextPageClick}
          onPreviousPageClick={onPreviousPageClick}
        />
      </Grid>
    </Grid>
  );
};

export default ItemList;
