import Frame from "../../components/frame/Frame";
import AddProductToCart from "../../components/common/AddProductToCart";
import useMenyvelgerenAddons from "../../hooks/useMenyvelgerenAddons";
import { selectMachineKey } from "../../redux/machineKeySlice";
import { MENYVELGEREN_MODULES } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addToBasket } from "../../redux/basketSlice";
import { showFooterNotification } from "../../redux/footerNotificationSlice";
import { useEffect } from "react";

const ProductPageView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pid = useParams().pid;
  const machineKey = useSelector(selectMachineKey);
  const [productData, isLoading] = useMenyvelgerenAddons(machineKey, pid);
  const { product, addons } = productData || {};

  const shouldRedirect = !isLoading && productData && addons === false;
  useEffect(() => {
    if (!isLoading && productData && addons === false) {
      dispatch(
        showFooterNotification({
          product: product,
          qty: 1,
        })
      );
      dispatch(
        addToBasket({
          product: product,
          addons: [],
          qty: 1,
        })
      );
      navigate(MENYVELGEREN_MODULES.MENU());
    }
  }, [dispatch, navigate, isLoading, product, productData, addons]);

  return (
    <Frame
      useBasketFooter={true}
      enableClickGuard={true}
      enablePadding={false}
      loading={isLoading || shouldRedirect}
    >
      {product && addons && (
        <AddProductToCart product={product} addons={addons} />
      )}
    </Frame>
  );
};

export default ProductPageView;
