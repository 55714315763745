import styled from "styled-components";
import AddonsTable from "./AddonsTable";
import useHardwareFormFactor from "../../hooks/useHardwareFormFactor";
import MenyvelgerenPagination from "./MenyvelgerenPagination";
import { useEffect, useState } from "react";
import {
  APPPROFILE,
  ENDPOINTS,
  MENYVELGEREN_MODULES,
  TEXTS,
} from "../../constants";
import { Box, Grid } from "@mui/material";
import { FORMATTER } from "../../services";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToBasket } from "../../redux/basketSlice";
import { AddToBasketFooter } from "./AddToBasketFooter";
import { showFooterNotification } from "../../redux/footerNotificationSlice";
import { selectStoreHasInPrice } from "../../redux/storeDataSlice";

const StyledProductTitle = styled.div`
  background: ${APPPROFILE.PROFILECOLOR};
  color: white;
  height: 130px;

  ${({ backgroundImage }) =>
    backgroundImage
      ? `
    background-color: rgba(0, 55, 112, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("${ENDPOINTS.image(backgroundImage)}");
    background-blend-mode: multiply;
    `
      : `
    background: ${APPPROFILE.PROFILECOLOR};
    border-top: 5px solid ${APPPROFILE.ACCENTCOLOR};
  `}

  .dialog-title {
    line-height: 40px;
    font-size: 24px;
    padding: 65px 25px 25px 25px;

    @media only screen and (max-width: 899px) {
      padding: 30px 25px 25px 25px;
    }

    @media only screen and (max-width: 599px) {
      font-size: 20px;
      background: ${APPPROFILE.PROFILECOLOR};
      padding: 15px 25px 10px 25px;
    }
  }
`;

const StyledProductInfo = styled.div`
  background: #f3f4f4;
  padding: 25px;

  .product-title {
    font-weight: bold;
    line-height: 35px;
  }

  .product-price {
    text-align: right;
    padding-top: 10px;

    &:last-of-type {
      padding-right: 40px;
    }

    .price {
      font-size: 26px;
      display: block;
      color: ${APPPROFILE.ACCENTCOLOR};
    }

    .description {
      display: block;
      color: rgba(0, 0, 0, 0.7);
    }
  }
`;

const ProductInfo = ({ product, qty, addonsSum }) => {
  const hasInPrice = useSelector(selectStoreHasInPrice);

  return (
    <StyledProductInfo>
      <Grid container>
        <Grid item xs={12} md={8}>
          <div className="product-title">{product.title}</div>
          <div className="product-description">{product.description}</div>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            marginTop: {
              xs: "15px",
              md: "0px",
            },
          }}
        >
          <Grid container>
            {hasInPrice && (
              <Grid item xs={6}>
                <div className="product-price price-in">
                  <span className="price">
                    {FORMATTER.formatCurrent(
                      [product.price_in, addonsSum.price_in],
                      qty
                    )}
                  </span>
                  <span className="description">{TEXTS.EATIN}</span>
                </div>
              </Grid>
            )}
            <Grid item xs={hasInPrice ? 6 : 12}>
              <div className="product-price price-out">
                <span className="price">
                  {FORMATTER.formatCurrent(
                    [product.price_out, addonsSum.price_out],
                    qty
                  )}
                </span>
                {hasInPrice && (
                  <span className="description">{TEXTS.TAKEOUT}</span>
                )}
                {!hasInPrice && <span className="description">Pris</span>}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledProductInfo>
  );
};

const calculateAddonsPrPage = (gridConfig, addons) => {
  if (addons && addons.length) {
    const pageSizeMap = [];
    let totalRowItemSize = 0;
    let numberOfItems = 0;

    for (const a of addons) {
      const rowItemSize = Math.ceil(a.length / 3);

      const nextRotalRowItemSize = totalRowItemSize + rowItemSize;
      if (nextRotalRowItemSize > gridConfig.addonsItemPrPage) {
        pageSizeMap.push(numberOfItems);
        totalRowItemSize = rowItemSize;
        numberOfItems = 1;
      } else {
        totalRowItemSize = nextRotalRowItemSize;
        numberOfItems++;
      }
    }

    if (numberOfItems > 0) {
      pageSizeMap.push(numberOfItems);
    }
    return pageSizeMap;
  }

  return [gridConfig.addonsItemPrPage];
};

const AddProductToCart = ({ addons, product }) => {
  const [qty, setQty] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { gridConfig } = useHardwareFormFactor();
  const addonsPageLength = calculateAddonsPrPage(gridConfig, addons);
  const addonsItemPrPage = addonsPageLength[page - 1];
  const pageItemStart = addonsPageLength
    .slice(0, page - 1)
    .reduce((p, a) => p + a, 0);

  const [selectedAddons, setSelectedAddons] = useState([]);
  const totalPageCount = addonsPageLength?.length ?? 1;
  const [addonsSum, setAddonsSum] = useState({
    price_in: 0,
    price_out: 0,
  });

  const handleAddProductToBasket = () => {
    dispatch(
      showFooterNotification({
        product: product,
        qty: qty,
      })
    );
    dispatch(
      addToBasket({
        product: product,
        addons: selectedAddons,
        qty: qty,
      })
    );
    navigate(MENYVELGEREN_MODULES.MENU());
  };

  const handleAddonSelectionChange = (newSelectedAddons) => {
    const sumAddonsIn = (newSelectedAddons || [])
      .map((e) => parseFloat(e.price_in))
      .filter((e) => e)
      .reduce((sum, a) => sum + a, 0);
    const sumAddonsOut = (newSelectedAddons || [])
      .map((e) => parseFloat(e.price_out))
      .filter((e) => e)
      .reduce((sum, a) => sum + a, 0);

    setAddonsSum({
      price_in: sumAddonsIn,
      price_out: sumAddonsOut,
    });
    setSelectedAddons(newSelectedAddons);
  };

  useEffect(() => {
    setSelectedAddons([]);
    setAddonsSum({
      price_in: 0,
      price_out: 0,
    });
  }, [product]);

  const visibleItems = [...(addons || [])].slice(
    pageItemStart,
    pageItemStart + addonsItemPrPage
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      <Grid item sx={{ width: "100%" }}>
        {gridConfig.addonsItemShowTitle && (
          <StyledProductTitle backgroundImage={product.picture}>
            <div className="dialog-title">Tilpass din bestilling</div>
          </StyledProductTitle>
        )}
        <ProductInfo product={product} addonsSum={addonsSum} qty={qty} />
        <Box>
          <AddonsTable
            onChange={handleAddonSelectionChange}
            selectedAddons={selectedAddons}
            addons={visibleItems}
          />
        </Box>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <MenyvelgerenPagination
          pageCount={totalPageCount}
          page={page}
          onChangePage={(e, page) => setPage(page)}
          onPreviousPageClick={() =>
            navigate(MENYVELGEREN_MODULES.PRODUCTLIST(product.mid))
          }
        />
        <AddToBasketFooter
          qty={qty}
          setQty={setQty}
          handleAddProductToBasket={handleAddProductToBasket}
        />
      </Grid>
    </Grid>
  );
};

export default AddProductToCart;
