import moment from "moment";
import { ENDPOINTS, MENYVELGER_MENUGROUP } from "./constants";

const makePostRequest = (url, data) => {
  const formData = new FormData();
  for (var k in data) {
    if (typeof data[k] !== "undefined") formData.append(k, data[k]);
  }
  return fetch(url, {
    method: "POST",
    cache: "no-cache",
    body: formData,
  });
};

const sendOrderRequest = async (
  endpoint,
  machineKey,
  bonus,
  basket,
  customerFormData
) => {
  const { fullName, phoneNumber, eatin, paymentprovider, ordercomment } =
    customerFormData;

  const newbasket = basket.map(function (o) {
    const newObj = JSON.parse(JSON.stringify(o));
    if (o && o.product && o.product.accounting) {
      newObj.product.accounting = JSON.parse(o.product.accounting);
    }
    return newObj;
  });

  const r = await makePostRequest(endpoint, {
    fullName: fullName,
    phoneNumber: phoneNumber,
    ordercomment: ordercomment,
    bonus: bonus,
    machinekey: machineKey,
    useragent: navigator ? navigator.userAgent : "",
    eatin: eatin,
    payment: paymentprovider,
    deliveryType: "now",
    deliveryTime: null,
    order: JSON.stringify(newbasket),
  });

  if (r && r.ok) return await r.json();
  return { status: "ERROR" };
};

export const SERVICES = {
  fetchMachineKeyFromPreviewKey: async (previewkey) => {
    const h = await makePostRequest(`${ENDPOINTS.base()}preview/machinekey`, {
      previewkey,
    });
    return await h.json();
  },
  fetchApplicationVersion: async () => {
    try {
      const h = await fetch(`/healthz`);
      if (h.ok) {
        const v = h.headers.get("x-application-version");
        if (v) return v;
      }
    } catch {}

    return 0;
  },
  fetchScreensavers: (machineKey) =>
    fetch(`${ENDPOINTS.base()}menyvelger/screensaver?machinekey=${machineKey}`),
  fetchAgreementData: (machineKey) =>
    fetch(`${ENDPOINTS.base()}menyvelger/agreement?machinekey=${machineKey}`),
  fetchMenuAndProductData: (machineKey) =>
    fetch(`${ENDPOINTS.base()}menyvelger/products?machinekey=${machineKey}`),
  fetchExtraOptions: (machineKey) =>
    fetch(
      `${ENDPOINTS.base()}menyvelger/extraoptions?machinekey=${machineKey}`
    ),
  fetchAddonsData: (machineKey, pid) =>
    fetch(
      `${ENDPOINTS.base()}menyvelger/addons?machinekey=${machineKey}&pid=${pid}`
    ),
  placeOrder: async (machineKey, bonus, basket, customerFormData) =>
    sendOrderRequest(
      `${ENDPOINTS.base()}bestillinger/placeorder`,
      machineKey,
      bonus,
      basket,
      customerFormData
    ),
  sendOrderStat: async (machineKey, bonus, basket, customerFormData) =>
    sendOrderRequest(
      `${ENDPOINTS.base()}bestillinger/orderstats`,
      machineKey,
      bonus,
      basket,
      customerFormData
    ),
};

export const FORMATTER = {
  parseNumber: (number) => {
    if (typeof number === "number") {
      return number;
    } else if (typeof number === "string") {
      const newNumber = number
        .replace(/,-/, "")
        .replace(/ /g, "")
        .replace(/,/g, ".");
      const parsed = parseFloat(newNumber);
      if (isNaN(parsed)) return 0;
      return parsed;
    }
    return 0;
  },
  formatDateTime: (time) => moment(time).format("DD.MM.YYYY HH:mm:ss"),
  formatDate: (time) => moment(time).format("DD.MM.YYYY"),
  formatCurrent: (number, qty, includeDecimal) => {
    let n;
    const parsedQty = parseInt(qty) ? parseInt(qty) : 1;
    if (typeof number === "number") {
      n = number;
    } else if (typeof number === "string") {
      n = FORMATTER.parseNumber(number);
    } else if (typeof number === "object" && number?.length) {
      n = number
        .map((e) => FORMATTER.parseNumber(e))
        .reduce((sum, a) => sum + a, 0);
    } else {
      return number;
    }

    if (isNaN(n)) return number;
    const formattedNumber = (n * parsedQty).toLocaleString("nb-NO", {
      minimumFractionDigits: includeDecimal ? 2 : 0,
      maximumFractionDigits: includeDecimal ? 2 : 0,
    });

    return includeDecimal ? formattedNumber : `${formattedNumber},-`;
  },
};

export const getMenuGroupById = (group) =>
  group && MENYVELGER_MENUGROUP[group]
    ? MENYVELGER_MENUGROUP[group]
    : MENYVELGER_MENUGROUP.OTHER;

export const saveLoginMachineName = (machineName) => {
  if (window.ToppBonusToolboxJS) {
    const vertical = window.innerHeight > window.innerWidth;
    window.ToppBonusToolboxJS.saveLoginMachineName(
      `M2${vertical ? "V" : "H"}`,
      machineName
    );
  }
};
