import React from "react";
import Basket from "../../components/common/Basket";
import Frame from "../../components/frame/Frame";

const BasketView = () => {
  return (
    <Frame useBasketFooter={true} enableClickGuard={true} enablePadding={true}>
      <Basket />
    </Frame>
  );
};

export default BasketView;
