import { Fragment } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { FORMATTER } from "../../services";
import { useTheme } from "@emotion/react";

const AddonsTable = ({ addons, onChange, selectedAddons }) => {
  const [selected, setSelected] = useState(
    [...selectedAddons].map((e) => ({ ...e }))
  );
  const theme = useTheme();
  const isLgScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const optionsWidth = isLgScreen ? 3 : 3;

  const handleSelectionClick = (row) => {
    const wasSelected = isSelected(row);

    const newSelected = [...selected].filter(
      (item) => item && item.addid !== row.addid
    );

    if (!wasSelected) newSelected.push(row);
    setSelected(newSelected);
    if (onChange) onChange(newSelected);
  };

  const isSelected = (row) => {
    return (
      selected.filter(
        (item) => item && item.addid === row.addid && item.aoid === row.aoid
      ).length > 0
    );
  };

  const renderOptions = (rows, offset, disableBorder) => {
    const components = [];

    for (let i = offset; i < optionsWidth + offset; i++) {
      const row = rows[i];
      if (row) {
        components.push(
          <TableCell
            scope="row"
            key={`addon_${row.addid}_${row.aoid}`}
            onClick={() => handleSelectionClick(row)}
            sx={{ borderWidth: disableBorder ? 0 : 1 }}
          >
            <Button
              key={`addonbtn_${row.addid}_${row.aoid}`}
              style={{ margin: "0px 10px", width: "calc(100% - 20px)" }}
              disableElevation
              variant={isSelected(row) ? "contained" : "outlined"}
            >
              {row.option}&nbsp;({FORMATTER.formatCurrent(row.price_out)})
            </Button>
          </TableCell>
        );
      } else {
        components.push(
          <TableCell key={`addonbtn_empty_${i}`} scope="row"></TableCell>
        );
      }
    }
    return components;
  };

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {(addons || []).map((row, idx) => {
            const numberOfRows = Math.max(
              1,
              Math.ceil(row.length / optionsWidth)
            );
            const numberOfExtraRows = Math.max(numberOfRows - 1, 0);

            let mainRow;
            let secondaryRows = [];
            if (row[0].disabled) {
              mainRow = (
                <TableRow key={`addonrow_${idx}`}>
                  <TableCell rowSpan={numberOfRows} component="th" scope="row">
                    {row[0].title}
                  </TableCell>
                  <TableCell key={`addonbtn_empty_1`} colSpan="3" scope="row">
                    <Typography color="secondary" sx={{ paddingLeft: "10px" }}>
                      Midlertidig utsolgt
                    </Typography>
                  </TableCell>
                </TableRow>
              );

              secondaryRows = [];
            } else {
              mainRow = (
                <TableRow key={`addonrow_${idx}`}>
                  <TableCell rowSpan={numberOfRows} component="th" scope="row">
                    {row[0].title}
                  </TableCell>
                  {renderOptions(row, 0, numberOfExtraRows > 0)}
                </TableRow>
              );

              secondaryRows = [...Array(numberOfExtraRows).keys()].map(
                (extraRowIdx) => {
                  return (
                    <TableRow key={`addonrow_${idx}_extra_${extraRowIdx}`}>
                      {renderOptions(
                        row,
                        (extraRowIdx + 1) * optionsWidth,
                        numberOfExtraRows > extraRowIdx + 1
                      )}
                    </TableRow>
                  );
                }
              );
            }

            return (
              <Fragment key={`addonrowfragment_${idx}`}>
                {mainRow}
                {secondaryRows}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AddonsTable;
