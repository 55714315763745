import React, { useEffect, useState } from "react";
import LoadingView from "./LoadingView";
import styled from "styled-components";
import { SERVICES } from "../../services";
import { useDispatch } from "react-redux";
import { setMachineKey } from "../../redux/machineKeySlice";
import { useParams } from "react-router-dom";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const StyledPreviewFrame = styled.div`
  background: #222222;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;

  .machine-type-selector-wrapper {
    .machine-type-selector {
      background: white;
      width: calc(300px);
      margin: auto;
      padding: 10px 10px;
      box-shadow: 0px 0px 5px 5px black;
    }
  }

  div.sxga {
    width: 1280px;
    height: calc(70px + 1024px);
    margin: 0px auto;
    padding: 35px 0px;

    iframe {
      width: 1280px;
      height: 1024px;
      border: 1px solid black;
      box-shadow: 0px 0px 5px 5px black;
    }
  }

  div.fhd-vertical {
    width: 1080px;
    height: calc(70px + 1920px);
    margin: 0px auto;
    padding: 35px 0px;

    iframe {
      width: 1080px;
      height: 1920px;
      border: 1px solid black;
      box-shadow: 0px 0px 5px 5px black;
    }
  }
`;

export const PreviewView = () => {
  const dispatch = useDispatch();
  const { previewkey } = useParams();

  useEffect(() => {
    const load = async () => {
      const resp = await SERVICES.fetchMachineKeyFromPreviewKey(previewkey);
      const { machinekey } = resp;
      dispatch(setMachineKey(machinekey));
      setTimeout(() => {
        window.location = "/#/preview";
      }, 500);
    };
    load();
  }, [previewkey, dispatch]);

  return <LoadingView />;
};

export const PreviewIframeView = () => {
  const [machineType, setMachineType] = useState("sxga");

  return (
    <StyledPreviewFrame>
      <div className="machine-type-selector-wrapper">
        <div className="machine-type-selector">
          <ToggleButtonGroup
            color="secondary"
            exclusive
            size="small"
            value={machineType}
            onChange={(event) => setMachineType(event.target.value)}
            aria-label="Platform"
          >
            <ToggleButton sx={{ width: 140 }} value="sxga">
              SXGA
            </ToggleButton>
            <ToggleButton sx={{ width: 140 }} value="fhd-vertical">
              FHD Vertikal
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      <div key={`machine-${machineType}`} className={machineType}>
        <iframe scrolling="no" src="/"></iframe>
      </div>
    </StyledPreviewFrame>
  );
};
