import { IconButton, TextField } from "@mui/material";
import styled from "styled-components";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const StyledQtyButtonWrapper = styled.div`
  display: flex;
  .subtract-button {
    line-height: 45px;
  }

  .qty-input-field {
    width: 70px;
    padding: 0px 10px;
    color: black;

    .MuiInput-root,
    .MuiInputLabel-root,
    input {
      color: black;
      -webkit-text-fill-color: rgba(0, 0, 0, 1);
    }
  }

  .add-button {
    line-height: 45px;
  }
`;

const QtyField = ({ onChange, value }) => {
  const handleOnChange = (value) => {
    const newValue = isNaN(value) ? 1 : value;
    if (onChange) onChange(Math.min(25, Math.max(newValue, 1)));
  };
  const handleButton = (idx) => handleOnChange(value + idx);

  return (
    <StyledQtyButtonWrapper>
      <div className="subtract-button">
        <IconButton
          color="primary"
          disabled={value <= 1}
          aria-label="Trekk fra"
          onClick={() => handleButton(-1)}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </div>
      <div className="qty-input-field">
        <TextField
          disabled
          variant="standard"
          label="Antall"
          onChange={(event) => handleOnChange(parseInt(event.target.value))}
          value={value}
          InputLabelProps={{
            shrink: true,
            readOnly: true,
          }}
        />
      </div>
      <div className="add-button">
        <IconButton
          color="primary"
          disabled={value >= 25}
          aria-label="Legg til"
          onClick={() => handleButton(1)}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </div>
    </StyledQtyButtonWrapper>
  );
};

export default QtyField;
