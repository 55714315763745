import { Button, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  APPPROFILE,
  MENYVELGEREN_MODULES,
  SCREENSAVER_INTERVAL_SECONDS,
  SCREENSAVER_RELOAD_SECONDS,
  TEXTS,
} from "../../constants";
import React, { useEffect, useState } from "react";
import Frame from "../../components/frame/Frame";
import styled from "styled-components";
import useHardwareFormFactor from "../../hooks/useHardwareFormFactor";
import { SERVICES } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { selectMachineKey } from "../../redux/machineKeySlice";
import { ENDPOINTS } from "../../constants";
import {
  selectStoreData,
  selectStoreHasInPrice,
} from "../../redux/storeDataSlice";
import { setEatIn } from "../../redux/basketSlice";

const defaultImage = {
  picture: "https://app-h6i0oplh.toppbonus.no/data/dQ4Or11zUI9EbmJ7.jpg",
};

const ScreensaverImageWrapper = styled("div")(({ image, height }) => ({
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundImage: `url(${image})`,
  width: "100vw",
  height: height,
  margin: "0px",
}));

const ScreensaverWelcomeMessage = styled("div")(({ height }) => ({
  width: "100vw",
  height: height,
  margin: "0px",
  background: APPPROFILE.PROFILECOLOR,
}));

const StartButtonGrid = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const handleEatInSelect = (eatIn) => {
    dispatch(setEatIn(eatIn));
    navigate(MENYVELGEREN_MODULES.MENU());
  };

  if (hasInPrice) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Grid item>
          <Button
            onClick={() => handleEatInSelect(false)}
            variant="contained"
            sx={{
              width: "calc(50vw - 125px)",
              padding: "20px",
              fontSize: "48px",
              boxShadow: "none",
              fontFamily: "Grandstander",
              background: APPPROFILE.ACCENTCOLOR,
            }}
          >
            {TEXTS.TAKEOUT}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => handleEatInSelect(true)}
            variant="contained"
            sx={{
              width: "calc(50vw - 125px)",
              padding: "20px",
              fontSize: "48px",
              boxShadow: "none",
              fontFamily: "Grandstander",
              background: APPPROFILE.ACCENTCOLOR,
            }}
          >
            {TEXTS.EATIN}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <Grid item>
        <Button
          component={Link}
          to={MENYVELGEREN_MODULES.MENU()}
          variant="contained"
          sx={{
            margin: "0px auto",
            width: "60vw",
            padding: "20px",
            fontSize: "48px",
            boxShadow: "none",
            background: APPPROFILE.ACCENTCOLOR,
          }}
        >
          Start her
        </Button>
      </Grid>
    </Grid>
  );
};

const ScreensaverStartButtons = () => {
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const { isNewFormFactor } = useHardwareFormFactor();

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ width: "100%", padding: isNewFormFactor ? "50px 0px" : "25px 0px" }}
    >
      <Grid item>
        <Typography
          sx={{
            width: "100%",
            paddingBottom: isNewFormFactor ? "60px" : "30px",
            fontSize: isNewFormFactor ? "36px" : "26px",
            textAlign: "center",
            boxShadow: "none",
            fontFamily: "Grandstander",
          }}
        >
          {hasInPrice ? "Vil du spise her eller ta med?" : "Sulten? Start her!"}
        </Typography>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <StartButtonGrid />
      </Grid>
    </Grid>
  );
};

const ScreensaverWelcomeMessageText = ({ singleLine, displayname }) => {
  return (
    <Grid item>
      {!singleLine && displayname && (
        <>
          <Typography
            sx={{
              padding: "0px",
              textAlign: "center",
              color: "white",
              fontSize: "48px",
            }}
          >
            Velkommen til
          </Typography>
          <Typography
            sx={{
              padding: "25px 0px",
              textAlign: "center",
              color: "white",
              fontSize: "48px",
              borderBottom: `5px solid ${APPPROFILE.ACCENTCOLOR}`,
            }}
          >
            {displayname}
          </Typography>
        </>
      )}
      {singleLine && displayname && (
        <Typography
          sx={{
            padding: "25px 0px",
            textAlign: "center",
            color: "white",
            fontSize: "36px",
          }}
        >
          Velkommen til {displayname}
        </Typography>
      )}
      {!displayname && (
        <Typography
          sx={{
            padding: "25px 0px",
            textAlign: "center",
            color: "white",
            fontSize: "48px",
            borderBottom: `5px solid ${APPPROFILE.ACCENTCOLOR}`,
          }}
        >
          Velkommen
        </Typography>
      )}
    </Grid>
  );
};

const Screensaver = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [applicationVersion, setApplicationVersion] = useState(0);
  const [activeItem, setActiveItem] = useState(0);
  const [items, setItems] = useState([]);
  const { isNewFormFactor, screensaverConfig } = useHardwareFormFactor();
  const machineKey = useSelector(selectMachineKey);
  const storeData = useSelector(selectStoreData);
  const { displayname } = storeData || {};

  useEffect(() => {
    const loadApplicationVersion = async () => {
      const newApplicationVersion = await SERVICES.fetchApplicationVersion();
      if (
        applicationVersion > 0 &&
        newApplicationVersion !== applicationVersion
      ) {
        window.location = "/";
      }
      setApplicationVersion(newApplicationVersion);
    };

    const loadData = async () => {
      loadApplicationVersion();
      const rs = await SERVICES.fetchScreensavers(machineKey);
      if (rs.ok) {
        const data = await rs.json();
        setItems(
          (data || []).map((e) => ({
            ...e,
            picture: ENDPOINTS.image(e.picture),
          }))
        );
        setIsLoading(false);
      }
    };
    loadData();
    const interval = setInterval(loadData, SCREENSAVER_RELOAD_SECONDS * 1000);
    return () => clearInterval(interval);
  }, [applicationVersion, machineKey]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeItem + 1 >= items.length) {
        setActiveItem(0);
      } else {
        setActiveItem(activeItem + 1);
      }
    }, SCREENSAVER_INTERVAL_SECONDS * 1000);
    return () => clearInterval(interval);
  }, [activeItem, items]);

  const visibleImage =
    items?.length > activeItem ? items[activeItem] : defaultImage;

  return (
    <Frame enablePadding={false} useBasketFooter={false} loading={isLoading}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", height: "100%" }}
      >
        <Grid item>
          <ScreensaverWelcomeMessage
            height={screensaverConfig.welcomeMessageHeight}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <ScreensaverWelcomeMessageText
                singleLine={!isNewFormFactor}
                displayname={displayname}
              />
            </Grid>
          </ScreensaverWelcomeMessage>
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <ScreensaverStartButtons />
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <Link to={MENYVELGEREN_MODULES.MENU()}>
            <ScreensaverImageWrapper
              image={visibleImage.picture}
              height={screensaverConfig.pictureHeight}
            />
          </Link>
        </Grid>
        <Grid item sx={{ width: "100%" }}></Grid>
      </Grid>
    </Frame>
  );
};

export default Screensaver;
