import { useDispatch, useSelector } from "react-redux";
import { getBasket, getEatIn } from "../redux/basketSlice";
import {
  selectStoreData,
  selectStoreHasInPrice,
  selectStoreShouldForwardOrder,
} from "../redux/storeDataSlice";
import { ConvertBasketToPrintableObject } from "../PrinterUtils";
import {
  selectProcessingOrder,
  selectProcessingOrderError,
  setProcessingOrder,
  setProcessingOrderError,
} from "../redux/processingOrderSlice";
import { SERVICES } from "../services";
import { selectMachineKey } from "../redux/machineKeySlice";
import useLocalReceiptCounter from "./useLocalReceiptCounter";

const useOrderProcessor = () => {
  const dispatch = useDispatch();
  const machineKey = useSelector(selectMachineKey);
  const basket = useSelector(getBasket);
  const storeData = useSelector(selectStoreData);
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const eatInRaw = useSelector(getEatIn);
  const eatIn = hasInPrice ? eatInRaw : false;
  const processingOrder = useSelector(selectProcessingOrder);
  const storeShouldForwardOrder = useSelector(selectStoreShouldForwardOrder);
  const processingOrderError = useSelector(selectProcessingOrderError);
  const localReceiptCounter = useLocalReceiptCounter();

  const setOrderError = (message) => {
    dispatch(setProcessingOrderError(message));
    dispatch(setProcessingOrder(false));
  };

  const handleOrderFeedback = (resp) => {
    if (resp.status === "ERROR") {
      setOrderError(
        "Det oppsto dessverre en feil ved behandling av din bestilling. Prøv igjen senere."
      );
    } else if (resp.status === "EMPTY") {
      setOrderError("Ingen registrerte produkter på din bestilling");
    } else if (resp.status === "CLOSED") {
      setOrderError("Stasjonen er dessverre stengt.");
    } else if (resp.status === "NO_ACCESS") {
      setOrderError("Du må logge inn før du kan sende inn en bestilling");
    } else if (resp.status === "SALDO_SALDO") {
      setOrderError(
        "Du har ikke nok bonus på kontoen til å kunne redusere beløpet"
      );
    } else if (resp.status === "OK") {
      return {
        orderid: resp.orderid,
        shouldPrint: true,
        redirectUrl: "/",
      };
    } else if (resp.status === "REDIRECT") {
      return {
        orderid: resp.orderid,
        shouldPrint: true,
        redirectUrl: resp.paymenturl,
      };
    } else {
      setOrderError(
        "Det oppsto en ukjent feil ved behandling av din bestilling."
      );
    }

    return {};
  };

  return {
    processOrder: async () => {
      if (basket) {
        dispatch(setProcessingOrder(true));
        dispatch(setProcessingOrderError(""));

        await SERVICES.sendOrderStat(machineKey, 0, basket, {
          eatin: eatIn,
          paymentprovider: 1,
        });

        if (storeShouldForwardOrder) {
          const resp = await SERVICES.placeOrder(machineKey, 0, basket, {
            eatin: eatIn,
            paymentprovider: 1,
          });

          const { shouldPrint, orderid, redirectUrl } =
            handleOrderFeedback(resp);

          if (shouldPrint) {
            const printableData = ConvertBasketToPrintableObject(
              storeData.displayname,
              orderid,
              hasInPrice,
              eatIn,
              basket
            );

            if (printableData && window.ToppBonusToolboxJS) {
              await window.ToppBonusToolboxJS.printMenyvelgerenReceipt(
                printableData
              );
            }
          }

          if (redirectUrl) {
            setTimeout(() => {
              window.location = redirectUrl;
            }, 2000);
          }
        } else {
          const receiptNumber = localReceiptCounter.getNextNumber();
          const printableData = ConvertBasketToPrintableObject(
            storeData.displayname,
            receiptNumber,
            hasInPrice,
            eatIn,
            basket
          );

          if (printableData && window.ToppBonusToolboxJS) {
            await window.ToppBonusToolboxJS.printMenyvelgerenReceipt(
              printableData
            );
          }

          setTimeout(() => {
            window.location = "/printinfo#/printinfo";
          }, 1000);
        }
      }
    },
    processingOrder: processingOrder,
    processingOrderError: processingOrderError,
  };
};

export default useOrderProcessor;
