export const ConvertBasketToPrintableObject = (
  displayName,
  orderNumber,
  hasInPrice,
  eatIn,
  basket
) => {
  const receiptlines = [];
  for (const item of basket) {
    const { qty, product, addons } = item;
    for (let idx = 0; idx < (qty > 0 ? qty : 0); idx++) {
      const addonsList = addons.map((a) => {
        let addonTitle = "";
        if (a.title === a.option) {
          addonTitle = a.title;
        } else {
          addonTitle = `${a.title} - ${a.option}`;
        }
        return {
          ProductName: addonTitle,
          PriceIn: parseFloat(a.price_in),
          PriceOut: parseFloat(a.price_out),
        };
      });

      receiptlines.push({
        ProductName: product.title,
        PriceIn: parseFloat(product.price_in),
        PriceOut: parseFloat(product.price_out),
        Location: product.location ? product.location : "",
        Addons: addonsList,
      });
    }
  }

  return {
    CompanyName: displayName,
    OrderNumber: orderNumber,
    ShowBothPrices: hasInPrice,
    EatIn: eatIn,
    Receiptlines: receiptlines,
    Accountinglines: [],
  };
};
