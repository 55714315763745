import { createSlice } from "@reduxjs/toolkit";

export const machineKeySlice = createSlice({
  name: "machinekey",
  initialState: {
    machinekey: false,
  },
  reducers: {
    setMachineKey: (state, { type, payload }) => {
      state.machinekey = payload;
    },
  },
});

export const { setMachineKey } = machineKeySlice.actions;

export const selectMachineKey = (state) => state.machineKeyData?.machinekey;

export default machineKeySlice.reducer;
