import React, { useEffect, useState } from "react";
import Frame from "../../components/frame/Frame";
import NumericKeyboard from "../../components/common/NumericKeyboard";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setMachineKey } from "../../redux/machineKeySlice";
import { saveLoginMachineName } from "../../services";

export const StyledRegistrationForm = styled("div")`
  width: 550px;
  margin: 0px auto;
`;

const RequireMachineView = () => {
  const [loadingMachineKey, setLoadingMachineKey] = useState(false);
  const dispatch = useDispatch();

  const handleOk = (value) => {
    if (value) {
      dispatch(setMachineKey(value));
      setLoadingMachineKey(true);
      setTimeout(() => {
        window.location = "/";
      }, 2000);
    }
  };

  useEffect(() => saveLoginMachineName("Unassigned"), []);

  return (
    <Frame enablePadding={true} loading={loadingMachineKey}>
      <StyledRegistrationForm>
        <Typography
          align="center"
          variant="h4"
          gutterBottom
          sx={{ padding: "5px 0px 15px 0px" }}
        >
          Oppstart av menyvelgeren
        </Typography>
        <Typography
          align="center"
          variant="body1"
          gutterBottom
          sx={{ padding: "5px 0px 25px 0px" }}
        >
          Vennligst angi koden du har fått oppgitt i feltet nedenfor.
        </Typography>
        <NumericKeyboard label={"Registreringsnøkkel"} onOk={handleOk} />
      </StyledRegistrationForm>
    </Frame>
  );
};

export default RequireMachineView;
