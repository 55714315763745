import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { VAT } from "../constants";
import { v4 as uuidv4 } from "uuid";

const MAX_QTY = 25;
const MIN_QTY = 1;

const isValidBasket = (state) => {
  if (typeof state.basket !== "object") return false;
  if (typeof state.lastUpdatedBasket !== "object") return false;

  const storeid = state.currentStoreId;
  if (!state.basket[storeid]) return false;
  if (!state.lastUpdatedBasket[storeid]) return false;

  return !(
    moment().diff(moment(state.lastUpdatedBasket[storeid])) / 1000.0 / 60 >
    45
  );
};

const ensureValidBasket = (state) => {
  if (typeof state.basket !== "object") state.basket = {};
  if (typeof state.ordercomment !== "object") state.ordercomment = {};
  if (typeof state.lastUpdatedBasket !== "object") state.lastUpdatedBasket = {};

  const storeid = state.currentStoreId;
  if (!state.basket[storeid]) {
    state.basket[storeid] = [];
    state.ordercomment[storeid] = "";
    state.lastUpdatedBasket[storeid] = moment().format();
  }

  if (!state.lastUpdatedBasket[storeid]) {
    state.basket[storeid] = [];
    state.ordercomment[storeid] = "";
    state.lastUpdatedBasket[storeid] = moment().format();
  }

  if (
    moment().diff(moment(state.lastUpdatedBasket[storeid])) / 1000.0 / 60 >
    45
  ) {
    state.lastUpdatedBasket[storeid] = moment().format();
    state.basket[storeid] = [];
    state.ordercomment[storeid] = "";
  } else {
    state.lastUpdatedBasket[storeid] = moment().format();
  }
};

const checkBaskeForPossibleDiscount = (basket) => {
  let productCountWithDiscount = 0;

  let newBasket = [...basket];
  if (basket) {
    newBasket = basket.map((row) => {
      row.no_use_bonus_override = false;
      return row;
    });

    newBasket.forEach((row, idx) => {
      if (row && row.product && row.product.tags && row.product.tags.length) {
        var isDiscount = false;
        try {
          isDiscount = row.product.tags.includes(
            "APP_TWO_PRODUCT_100_DISCOUNT"
          );
        } catch (e) {}

        if (isDiscount) {
          productCountWithDiscount =
            productCountWithDiscount + (row.qty ? row.qty : 1);
        }
      }
    });

    var remainingBonusFeatures = Math.floor(productCountWithDiscount / 2) * 2;
    newBasket = newBasket.map((row, idx) => {
      if (row && row.product && row.product.tags && row.product.tags.length) {
        var isDiscount = false;
        try {
          isDiscount = row.product.tags.includes(
            "APP_TWO_PRODUCT_100_DISCOUNT"
          );
        } catch (e) {}

        if (isDiscount && remainingBonusFeatures > 0) {
          row.no_use_bonus_override = true;
          remainingBonusFeatures =
            remainingBonusFeatures - (row.qty ? row.qty : 1);
        }
      }

      return row;
    });
  }

  const filteredBasket = newBasket.filter((row) => !row.readOnly);

  var discountPriceCount = Math.floor(productCountWithDiscount / 2);
  if (discountPriceCount > 0) {
    filteredBasket.push({
      readOnly: true,
      addons: [],
      price_in: discountPriceCount * -100,
      price_out: discountPriceCount * -100,
      qty: 1,
      product: {
        accounting: null,
        aid: "",
        description: "",
        disabled: false,
        food_groceries: false,
        location: null,
        mid: "",
        no_bonus: "1",
        no_food: false,
        no_use_bonus: "1",
        picture: null,
        picturesize: null,
        pid: "",
        price_in: "",
        price_out: "",
        sumcode: null,
        tags: [],
        title: "Kampanjerabatt",
        tobacco_cigarettes: false,
        tobacco_snuff: false,
      },
    });
  }

  return filteredBasket;
};

export const storeDataSlice = createSlice({
  name: "basketSlice",
  initialState: {
    basket: {},
    currentStoreId: 0,
    lastUpdatedBasket: {},
    eatIn: false,
    useBonus: false,
    ordercomment: {},
    selectedDeliveryTime: {
      type: "now",
      time: null,
    },
  },
  reducers: {
    setCurrentStoreId: (state, { type, payload }) => {
      state.currentStoreId = payload;
    },
    addToBasket: (state, { type, payload }) => {
      ensureValidBasket(state);
      const { product, addons, qty, visibleComment, comment } = payload;
      let processedAddons = [];
      if (addons) {
        for (let i in addons) {
          if (!addons[i]["disabled"]) processedAddons.push(addons[i]);
        }
      }

      let price_out = product.price_out * 1;
      let price_in = product.price_in * 1;

      for (let i in processedAddons) {
        price_out += processedAddons[i].price_out * 1;
        price_in += processedAddons[i].price_in * 1;
      }

      const processedSortedAddons = (processedAddons || []).sort(
        (a, b) => a?.sortkey - b?.sortkey
      );

      const newBasket = { ...state.basket };
      const newState = [...(newBasket[state.currentStoreId] || [])];
      newState.push({
        id: uuidv4(),
        product: { ...product },
        addons: [...processedSortedAddons],
        qty: qty,
        price_out: price_out,
        price_in: price_in,
        visibleComment: visibleComment === true,
        comment: comment,
      });

      newBasket[state.currentStoreId] = newState;

      newBasket[state.currentStoreId] = checkBaskeForPossibleDiscount(
        newBasket[state.currentStoreId]
      );
      state.basket = newBasket;
    },
    setProductQty: (state, { type, payload }) => {
      ensureValidBasket(state);
      const { id, qty } = payload;
      const validQty = Math.min(MAX_QTY, Math.max(MIN_QTY, qty));
      const newBasket = { ...state.basket };
      const newStoreBasket = [...(newBasket[state.currentStoreId] || [])];
      newBasket[state.currentStoreId] = newStoreBasket.map((item) => {
        if (item.id === id) {
          const newItem = { ...item };
          newItem.qty = validQty;
          return newItem;
        } else {
          return item;
        }
      });

      newBasket[state.currentStoreId] = checkBaskeForPossibleDiscount(
        newBasket[state.currentStoreId]
      );
      state.basket = newBasket;
    },
    setProductComment: (state, { type, payload }) => {
      ensureValidBasket(state);
      const { id, visibleComment, comment } = payload;
      const newBasket = { ...state.basket };
      const newStoreBasket = [...(newBasket[state.currentStoreId] || [])];
      newBasket[state.currentStoreId] = newStoreBasket.map((item) => {
        if (item.id === id) {
          const newItem = { ...item };
          newItem.visibleComment = visibleComment;
          newItem.comment = comment;
          return newItem;
        } else {
          return item;
        }
      });
      state.basket = newBasket;
    },
    deleteProduct: (state, { type, payload }) => {
      ensureValidBasket(state);
      const newBasket = { ...state.basket };
      const newStoreBasket = [...(newBasket[state.currentStoreId] || [])];
      newBasket[state.currentStoreId] = newStoreBasket.filter(
        (item) => item.id !== payload
      );

      newBasket[state.currentStoreId] = checkBaskeForPossibleDiscount(
        newBasket[state.currentStoreId]
      );
      state.basket = newBasket;
    },
    setEatIn: (state, { type, payload }) => {
      state.eatIn = payload;
    },
    setUseBonus: (state, { type, payload }) => {
      state.useBonus = payload;
    },
    setOrderComment: (state, { type, payload }) => {
      const newOrderComment = { ...state.ordercomment };
      newOrderComment[state.currentStoreId] = payload;
      state.ordercomment = newOrderComment;
    },
    checkoutCompleted: (state) => {
      ensureValidBasket(state);
      state.basket[state.currentStoreId] = [];
      state.lastUpdated = null;
      state.eatIn = false;
      state.useBonus = false;
      state.selectedDeliveryTime = {
        type: "now",
        time: null,
      };
    },
  },
});

export const {
  setCurrentStoreId,
  addToBasket,
  setProductQty,
  setProductComment,
  deleteProduct,
  setEatIn,
  setUseBonus,
  setOrderComment,
  checkoutCompleted,
} = storeDataSlice.actions;

export const getBasket = (state) => {
  if (
    state &&
    state.basketData &&
    state.basketData.basket &&
    state.basketData.currentStoreId &&
    state.basketData.currentStoreId > 0
  ) {
    if (isValidBasket(state.basketData)) {
      const id = state.basketData.currentStoreId;
      return state.basketData.basket[id] ? state.basketData.basket[id] : [];
    }
  }
  return [];
};

export const getBasketCount = (state) => {
  const basket = getBasket(state);
  return basket && basket.length
    ? basket.map((e) => (e.qty ? e.qty : 1)).reduce((a, b) => a + b, 0)
    : 0;
};

export const getEatIn = (state) =>
  state && state.basketData && state.basketData.eatIn
    ? state.basketData.eatIn
    : false;

export const getUseBonus = (state) =>
  state && state.basketData && state.basketData.useBonus
    ? state.basketData.useBonus
    : false;

export const getOrderComment = (state) =>
  state &&
  state.basketData &&
  state.basketData.currentStoreId &&
  state.basketData.ordercomment &&
  state.basketData.ordercomment[state.basketData.currentStoreId]
    ? state.basketData.ordercomment[state.basketData.currentStoreId]
    : "";

export const getBasketTotals = (state) => {
  const calculateTotal = (state, currentInprice) => {
    const basket = getBasket(state);
    const priceColumn = currentInprice ? "price_in" : "price_out";
    const foodVatFactor =
      (100.0 + (currentInprice ? VAT.EATIN : VAT.TAKEOUT)) / 100.0;
    const noFoodVatFactor = (100.0 + VAT.NO_FOOD) / 100.0;
    const foodGroceriesVatFactor = (100.0 + VAT.FOOD_GROCERIES) / 100.0;

    let sumTotalOrder = 0;
    let sumTotalVatOrder = 0;

    if (basket && basket.length > 0) {
      for (let i in basket) {
        const no_food = basket[i]["product"]["no_food"];
        const food_groceries = basket[i]["product"]["food_groceries"];
        const qty = basket[i]["qty"] * 1;

        let vatFactor = 0;
        if (no_food) {
          vatFactor = noFoodVatFactor;
        } else if (food_groceries) {
          vatFactor = foodGroceriesVatFactor;
        } else {
          vatFactor = foodVatFactor;
        }

        const productPrice = basket[i][priceColumn] * qty;
        const productVat =
          Math.round((productPrice - productPrice / vatFactor) * 100) / 100;

        sumTotalOrder += productPrice;
        sumTotalVatOrder += productVat;
      }
    }

    sumTotalOrder = Math.round(sumTotalOrder * 100) / 100;
    sumTotalVatOrder = Math.round(sumTotalVatOrder * 100) / 100;

    return {
      sumTotal: sumTotalOrder,
      sumTotalVat: sumTotalVatOrder,
      sumTotalGross: sumTotalOrder - sumTotalVatOrder,
    };
  };

  return {
    priceIn: calculateTotal(state, true),
    priceOut: calculateTotal(state, false),
  };
};

export default storeDataSlice.reducer;
