import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REDUX_DATA_STATUS } from "../constants";
import { SERVICES } from "../services";
import { sleep } from "../components/utils/sleep";

export const loadMenyvelgeren = createAsyncThunk(
  "menyvelgeren/loadProducts",
  async (store) => {
    if (store) {
      for (let i = 0; i < 5; i++) {
        try {
          const r = await SERVICES.fetchMenuAndProductData(store);
          if (r.ok) {
            const data = await r.json();
            return data;
          } else {
            await sleep(i);
          }
        } catch (e) {
          await sleep(i);
        }
      }

      window.location.reload();
    }
    return false;
  }
);

export const menyvelgerenSlice = createSlice({
  name: "menyvelgeren",
  initialState: {
    data: null,
    status: REDUX_DATA_STATUS.IDLE,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadMenyvelgeren.pending, (state) => {
        state.status = REDUX_DATA_STATUS.LOADING;
      })
      .addCase(loadMenyvelgeren.fulfilled, (state, action) => {
        if (action.payload) {
          state.status = REDUX_DATA_STATUS.IDLE;
          state.data = action.payload ? action.payload : null;
        } else {
          state.status = REDUX_DATA_STATUS.NOT_FOUND;
          state.data = null;
        }
      });
  },
});

export const selectMenyvelgerenMenus = (state) =>
  state.menyvelgerenData?.data?.length
    ? state.menyvelgerenData?.data?.filter((e) => e).map((e) => e.menu)
    : [];

export const selectMenyvelgerenProducts = (state, mid) => {
  if (state.menyvelgerenData?.data?.length) {
    const selectedItem = state.menyvelgerenData?.data?.filter(
      (e) => e.menu?.mid === mid
    );

    return selectedItem?.length ? selectedItem[0]["products"] : [];
  }
  return [];
};

export const selectMenyvelgerenLoading = (state) =>
  !(state.menyvelgerenData?.status !== REDUX_DATA_STATUS.LOADING);

export default menyvelgerenSlice.reducer;
