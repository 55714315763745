import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import DeleteIcon from "@mui/icons-material/Delete";
import QtyField from "./QtyField";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import styled from "styled-components";
import CheckoutEmpty from "./CheckoutEmpty";
import EditIcon from "@mui/icons-material/Edit";
import { FORMATTER } from "../../services";
import { useState } from "react";
import { Link } from "react-router-dom";
import { List } from "@mui/material";
import { selectStoreHasInPrice } from "../../redux/storeDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { MENYVELGEREN_MODULES, TEXTS } from "../../constants";
import {
  deleteProduct,
  getBasket,
  getBasketCount,
  getBasketTotals,
  getEatIn,
  setEatIn,
  setProductQty,
} from "../../redux/basketSlice";

export const StyledProductTitle = styled.div`
  line-height: 28px;
  font-weight: bold;
`;

export const StyledListNoPad = styled(List)`
  padding: 0;
  margin: 0;

  .MuiListItem-root {
    padding: 0;
    margin: 0;
  }

  .MuiListItem-root {
    padding: 0;
    margin: 0;
  }

  .MuiListItemText-root {
    padding: 0;
    margin: 0;
  }

  .qty-line {
    padding-bottom: 5px;
  }
`;

export const StyledTimeSelector = styled.div`
  margin: 15px 25px;
  display: flex;

  .clock-separator {
    line-height: 30px;
    padding: 0px 10px;
  }

  .clock-input-control.MuiFormControl-root {
    width: 75px;
    padding: 0;
    margin: 0;
    height: 30px;
  }

  .clock-input-field {
    width: 75px;
  }
`;

const ConfirmDeleteProduct = ({ open, setOpen, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="alert-dialog-title">Er du sikker?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Er du sikker på at du vil fjerne produktet fra handlekurven?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpen(false)}
          disableElevation
        >
          Nei, behold produktet
        </Button>
        <div style={{ flex: "1 0 0" }} />
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
            if (onConfirm) onConfirm();
          }}
          autoFocus
        >
          Ja, slett fra handlekurven
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Basket = ({ readonly }) => {
  const dispatch = useDispatch();
  const totals = useSelector(getBasketTotals);
  const basketCount = useSelector(getBasketCount);
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const eatInRaw = useSelector(getEatIn);
  const eatIn = hasInPrice ? eatInRaw : false;
  const basket = useSelector(getBasket);
  const [deleteAlert, setDeleteAlert] = useState({
    visible: false,
    product: {},
  });
  const { sumTotal, sumTotalVat } = totals[eatIn ? "priceIn" : "priceOut"];

  const handleChangeEatIn = (event) =>
    dispatch(setEatIn(event.target.value === "true"));

  const renderAddons = (addons) => {
    return (addons || [])
      .map((addon) => {
        if (addon.option === addon.title) {
          return `- ${addon.title}`;
        } else {
          return `- ${addon.title} - ${addon.option}`;
        }
      })
      .map((item, idx) => (
        <ListItem key={`addons_list_${idx}`}>
          <ListItemText primary={item} />
        </ListItem>
      ));
  };

  const handleQtyChange = (product, qty) =>
    dispatch(
      setProductQty({
        id: product.id,
        qty: qty,
      })
    );

  const handleDeleteItem = (product) =>
    setDeleteAlert({
      visible: true,
      product: product,
    });

  return basketCount === 0 ? (
    <CheckoutEmpty />
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <Paper sx={{ margin: readonly ? "0" : "25px 0px" }}>
          <ConfirmDeleteProduct
            open={deleteAlert.visible}
            setOpen={() => setDeleteAlert({ visible: false, product: {} })}
            onConfirm={() => dispatch(deleteProduct(deleteAlert.product.id))}
          />
          {!readonly && (
            <>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{ padding: "25px 15px" }}
              >
                Din bestilling
              </Typography>
              <Divider />
            </>
          )}
          {readonly && (
            <>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    component="div"
                    gutterBottom
                    sx={{ padding: "10px 15px" }}
                  >
                    Din bestilling
                  </Typography>
                </Grid>
                <Grid item sx={{ textAlign: "right" }}>
                  <Button
                    component={Link}
                    variant="contained"
                    to={MENYVELGEREN_MODULES.BASKET()}
                    disableElevation
                    sx={{ padding: "7px 15px", marginRight: "15px" }}
                  >
                    <EditIcon sx={{ marginRight: "15px" }} />
                    Endre
                  </Button>
                </Grid>
              </Grid>

              <Divider />
            </>
          )}

          <Box
            sx={{ maxHeight: "55vh", overflowX: "auto", overflowY: "visible" }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell style={{ width: "170px" }}>&nbsp;</TableCell>
                  <TableCell
                    align="right"
                    style={{ width: "80px", fontWeight: "bold" }}
                  >
                    Pris
                  </TableCell>
                  {!readonly && (
                    <TableCell style={{ width: "60px" }}>&nbsp;</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(basket || []).map((item, idx) => (
                  <TableRow
                    key={`basketitem_${idx}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <StyledProductTitle>
                        {item.qty}x {item.product.title}
                      </StyledProductTitle>
                      <StyledListNoPad>
                        {renderAddons(item.addons)}
                      </StyledListNoPad>
                    </TableCell>
                    <TableCell>
                      {!readonly && (
                        <QtyField
                          value={item.qty}
                          onChange={(qty) => handleQtyChange(item, qty)}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ verticalAlign: readonly ? "top" : undefined }}
                    >
                      {FORMATTER.formatCurrent(
                        eatIn ? item.price_in : item.price_out
                      )}
                    </TableCell>
                    {!readonly && (
                      <TableCell align="right">
                        <IconButton
                          onClick={() => handleDeleteItem(item)}
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>

          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell>
                  {hasInPrice && (
                    <ToggleButtonGroup
                      exclusive
                      color="secondary"
                      value={eatIn ? "true" : "false"}
                      onChange={handleChangeEatIn}
                      aria-label="Platform"
                    >
                      <ToggleButton value="false" sx={{ width: "150px" }}>
                        <ShoppingBagIcon sx={{ marginRight: "10px" }} />
                        {TEXTS.TAKEOUT}
                      </ToggleButton>
                      <ToggleButton value="true" sx={{ width: "150px" }}>
                        <RestaurantIcon sx={{ marginRight: "10px" }} />
                        {TEXTS.EATIN}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  Totalt ({eatIn ? TEXTS.EATIN : TEXTS.TAKEOUT})
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  {FORMATTER.formatCurrent(sumTotal)}
                </TableCell>
                {!readonly && <TableCell>&nbsp;</TableCell>}
              </TableRow>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell align="right">Inkl. mva</TableCell>
                <TableCell align="right">
                  {FORMATTER.formatCurrent(sumTotalVat)}
                </TableCell>
                {!readonly && <TableCell>&nbsp;</TableCell>}
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      {!readonly && (
        <Grid item xs={12} sx={{ marginBottom: "50px" }}>
          <Button
            component={Link}
            variant="contained"
            to={MENYVELGEREN_MODULES.MENU()}
            disableElevation
            sx={{ padding: "25px 45px", marginTop: "50px" }}
          >
            Handle mer
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default Basket;
