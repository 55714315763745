import { createSlice } from "@reduxjs/toolkit";

export const footerNotificationSlice = createSlice({
  name: "footerNotification",
  initialState: {
    lastExecutedCounter: 0,
    animateCounter: 0,
    newProductAdded: null,
  },
  reducers: {
    showFooterNotification: (state, { type, payload }) => {
      state.animateCounter = state.animateCounter + 1;
      state.newProductAdded = payload;
    },
    executeFooterNotification: (state, { type, payload }) => {
      state.lastExecutedCounter = payload;
    },
  },
});

export const { showFooterNotification, executeFooterNotification } =
  footerNotificationSlice.actions;

export const selectFooterNotification = (state) => ({
  animateCounter: state.footerNotification?.animateCounter,
  newProductAdded: state.footerNotification?.newProductAdded,
  lastExecutedCounter: state.footerNotification?.lastExecutedCounter,
});

export default footerNotificationSlice.reducer;
