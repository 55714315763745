import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";

const BackdropLoader = ({ open, message }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={2}
          sx={{
            textAlign: "center",
          }}
        >
          <CircularProgress
            color="secondary"
            size={100}
            sx={{ marginBottom: "50px" }}
          />
          <Typography variant="h4">{message || "Vent litt"}</Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default BackdropLoader;
