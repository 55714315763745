import { styled } from "@mui/system";
import { APPPROFILE } from "../../constants";
import useHardwareFormFactor from "../../hooks/useHardwareFormFactor";

export const StyledHeader = styled("div")({
  height: 57,
  borderTop: 0,
  borderBottom: `7px solid ${APPPROFILE.ACCENTCOLOR}`,
  background: APPPROFILE.PROFILECOLOR,
  ".styledHeaderLogo": {
    background: `url('${APPPROFILE.LOGO}')`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    width: 210,
    height: 50,
    marginLeft: 15,
  },
  "&.big-header": {
    borderBottom: `10px solid ${APPPROFILE.ACCENTCOLOR}`,
    height: 80,
    ".styledHeaderLogo": {
      width: 270,
      height: 70,
    },
  },
});

const Header = () => {
  const { isNewFormFactor } = useHardwareFormFactor();

  return (
    <StyledHeader className={isNewFormFactor ? "big-header" : "normal"}>
      <div
        onClick={() => (window.location = "/")}
        className="styledHeaderLogo"
      ></div>
    </StyledHeader>
  );
};

export default Header;
