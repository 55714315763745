import React, { useRef, useState } from "react";
import Keyboard from "react-simple-keyboard";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import "react-simple-keyboard/build/css/index.css";

export const StyledNumericKeyboard = styled("div")`
  width: 550px;

  .hg-theme-default {
    background: transparent;
  }

  .hg-theme-default .hg-button.hg-standardBtn,
  .hg-button.hg-functionBtn.hg-button-bksp,
  .hg-button.hg-functionBtn.hg-button-ok {
    width: 40px;
    padding: 35px;
    background: #555555;
    color: white;
    font-size: 24px;
    font-weight: bold;
  }

  .text-field-wrapper {
    padding: 5px;
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }
`;

const NumericKeyboard = ({ label, onOk }) => {
  const [currentValue, setCurrentValue] = useState("");
  const keyboard = useRef();

  const onChange = (input) => setCurrentValue(input);

  const onKeyPress = (button) => {
    if (button === "{ok}") {
      onOk(currentValue);
    }
  };

  const onChangeInput = (event) => {
    const input = event.target.value;
    setCurrentValue(input);
    keyboard.current.setInput(input);
  };

  return (
    <StyledNumericKeyboard>
      <div className="text-field-wrapper">
        <TextField
          id="field-value"
          variant="outlined"
          label={label}
          value={currentValue}
          onChange={(event) => onChangeInput(event)}
        />
      </div>
      <Keyboard
        keyboardRef={(r) => (keyboard.current = r)}
        onChange={onChange}
        onKeyPress={onKeyPress}
        layout={{
          default: ["7 8 9", "4 5 6", "1 2 3", "{bksp} 0 {ok}"],
        }}
        display={{
          "{bksp}": "&laquo;",
          "{ok}": "OK",
        }}
      />
    </StyledNumericKeyboard>
  );
};

export default NumericKeyboard;
