import { Button, Grid, Paper } from "@mui/material";
import { MENYVELGEREN_MODULES } from "../../constants";
import { Link } from "react-router-dom";

const CheckoutEmpty = () => {
  return (
    <Paper
      sx={{ margin: "35px 0px", padding: "100px 50px", textAlign: "center" }}
      elevation={4}
    >
      <Grid container>
        <Grid item xs={12} sx={{ fontSize: "28px" }}>
          <b>Din handlekurv er tom</b>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: "50px" }}>
          <Button
            disableElevation
            component={Link}
            variant="contained"
            color="success"
            to={MENYVELGEREN_MODULES.MENU()}
            sx={{ padding: "20px 125px" }}
          >
            Gå til meny
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CheckoutEmpty;
