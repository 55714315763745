import React, { useEffect } from "react";
import Frame from "../../components/frame/Frame";
import "animate.css/animate.css";
import PrintIcon from "@mui/icons-material/Print";
import { Grid } from "@mui/material";
import { APPPROFILE } from "../../constants";
import styled from "styled-components";
import useHardwareFormFactor from "../../hooks/useHardwareFormFactor";

const DownArrowAnimation = (props) => {
  const numberOfArrows = props.numberOfArrows ?? 4;
  const animationLength = props.animationLength ?? 1.5;
  const arrowsOffset = props.arrowsOffset ?? 60;
  const arrowSize = props.arrowSize ?? 90;
  const arrowDepthOffset = props.arrowDepthOffset ?? 0.7;

  const stepsCss = [...new Array(numberOfArrows).keys()]
    .map((i) => i + 1)
    .map((idx) => {
      const delayStep = animationLength / (numberOfArrows + 1);
      const delay = (numberOfArrows - idx) * delayStep;
      return `
    .arrows path.a${idx} {
      animation-delay: -${delay}s;
      -webkit-animation-delay: -${delay}s;
    }`;
    });

  const StyledDownArrowAnimation = styled.div`
    .arrows {
      width: ${arrowSize}px;
      height: ${numberOfArrows * (arrowSize - 1)}px;
      position: absolute;
      left: 50%;
      margin-left: -${arrowSize / 2}px;
      bottom: 20px;
    }

    .arrows path {
      stroke: ${APPPROFILE.ACCENTCOLOR};
      fill: transparent;
      stroke-width: 7px;
      animation: arrow ${animationLength}s infinite;
      -webkit-animation: arrow ${animationLength}s infinite;
    }

    @keyframes arrow {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    @-webkit-keyframes arrow {
      0% {
        opacity: 0;
      }
      40% {
        opacity: 1;
      }
      80% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
    .arrows path.a1 {
      animation-delay: -1s;
      -webkit-animation-delay: -1s;
    }

    .arrows path.a2 {
      animation-delay: -0.5s;
      -webkit-animation-delay: -0.5s;
    }

    ${stepsCss.join("\r\n")}
  `;

  return (
    <StyledDownArrowAnimation>
      <svg className="arrows">
        {[...new Array(numberOfArrows).keys()]
          .map((i) => i + 1)
          .map((idx) => {
            const top = arrowsOffset * (idx - 1);

            return (
              <path
                className={`a${idx}`}
                d={`M0 ${top} L${arrowSize / 2} ${Math.round(
                  top + arrowDepthOffset * (arrowSize / 2 + 2)
                )} L${arrowSize} ${top}`}
              ></path>
            );
          })}
      </svg>

      <path class="b1" d="M0 0 L30 32 L60 0"></path>
      <path class="b2" d="M0 20 L30 52 L60 20"></path>
    </StyledDownArrowAnimation>
  );
};

const PrintInfoView = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location = "/";
    }, 5000);
  }, []);

  const { printscreenConfig } = useHardwareFormFactor();

  return (
    <Frame enablePadding={true} useBasketFooter={false}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Grid item>
          <PrintIcon
            sx={{ fontSize: 60, color: APPPROFILE.ACCENTCOLOR }}
            className="animate__animated animate__bounce animate__infinite"
          />
        </Grid>
        <Grid item sx={{ fontSize: "2em", marginTop: "40px" }}>
          Takk for din bestilling.
        </Grid>
        <Grid item sx={{ fontSize: "2em", marginTop: "20px" }}>
          Kvittering leveres i kassen
        </Grid>
        {printscreenConfig.showDownArrows && <DownArrowAnimation />}
      </Grid>
    </Frame>
  );
};

export default PrintInfoView;
