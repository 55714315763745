import LoadingView from "../../views/system/LoadingView";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadMenyvelgeren } from "../../redux/menyvelgerenDataSlice";
import { selectMachineKey } from "../../redux/machineKeySlice";
import { Navigate, Outlet } from "react-router-dom";
import { loadStoreData, selectStoreData } from "../../redux/storeDataSlice";
import { setCurrentStoreId } from "../../redux/basketSlice";
import { saveLoginMachineName } from "../../services";

const MachineKeyGuard = () => {
  const dispatch = useDispatch();
  const machineKey = useSelector(selectMachineKey);
  const storeData = useSelector(selectStoreData);

  useEffect(() => {
    dispatch(setCurrentStoreId(1));
    if (machineKey) {
      dispatch(loadStoreData(machineKey));
      dispatch(loadMenyvelgeren(machineKey));
    }
  }, [machineKey, dispatch]);

  useEffect(() => {
    if (storeData?.displayname) saveLoginMachineName(storeData?.displayname);
  }, [storeData]);

  if (!machineKey) return <Navigate to={"/set-machine-key"} replace />;
  if (!storeData) return <LoadingView />;
  return <Outlet />;
};

export default MachineKeyGuard;
