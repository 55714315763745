import React from "react";
import RequireMachineView from "./views/system/RequireMachineView";
import MenuGroupView from "./views/menyvelgeren/MenuGroupView";
import ProductListView from "./views/menyvelgeren/ProductListView";
import BasketView from "./views/basket/BasketView";
import Screensaver from "./views/system/ScreensaverView";
import ProductPageView from "./views/menyvelgeren/ProductPageView";
import PrintInfoView from "./views/system/PrintInfoView";
import CheckoutView from "./views/basket/CheckoutView";
import { CssBaseline } from "@mui/material";
import { HashRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { APPPROFILE, MENYVELGEREN_MODULES, isDebug } from "./constants";
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from "react-redux";
import { store } from "./redux/store";
import { useEffect } from "react";
import { selectMachineKey } from "./redux/machineKeySlice";
import { loadExtraOptions } from "./redux/storeDataSlice";
import "animate.css";
import MenuView from "./views/menyvelgeren/MenuView";
import { PreviewIframeView, PreviewView } from "./views/system/PreviewView";
import MachineKeyGuard from "./components/router/MachineKeyGuard";

const AppViewRouter = () => {
  const dispatch = useDispatch();
  const machineKey = useSelector(selectMachineKey);

  useEffect(() => {
    if (machineKey) {
      dispatch(loadExtraOptions(machineKey));
    }
  }, [machineKey, dispatch]);

  return (
    <Routes>
      <Route element={<MachineKeyGuard />}>
        <Route
          exact
          path={MENYVELGEREN_MODULES.BASKET()}
          element={<BasketView />}
        />
        <Route
          exact
          path={MENYVELGEREN_MODULES.CHECKOUT()}
          element={<CheckoutView />}
        />
        <Route
          exact
          path={MENYVELGEREN_MODULES.MENU()}
          element={<MenuView />}
        />
        <Route
          exact
          path={MENYVELGEREN_MODULES.MENUGROUP(":group")}
          element={<MenuGroupView />}
        />
        <Route
          exact
          path={MENYVELGEREN_MODULES.PRODUCTLIST(":mid")}
          element={<ProductListView />}
        />
        <Route
          exact
          path={MENYVELGEREN_MODULES.PRODUCTPAGE(":pid")}
          element={<ProductPageView />}
        />
        <Route exact path="/printinfo" element={<PrintInfoView />} />
        <Route path="*" element={<Screensaver />} />
      </Route>

      <Route exact path={"/set-machine-key"} element={<RequireMachineView />} />
      <Route
        exact
        path={"/set-preview-key/:previewkey"}
        element={<PreviewView />}
      />
      <Route exact path={"/preview"} element={<PreviewIframeView />} />
    </Routes>
  );
};

const themeOptions = createTheme({
  palette: {
    type: "light",
    primary: {
      main: APPPROFILE.PROFILECOLOR,
    },
    secondary: {
      main: APPPROFILE.ACCENTCOLOR,
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Ubuntu",
  },
});

const DevTools = () => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 1000,
        padding: "10px",
        background: "blue",
        color: "white",
        width: "80vw",
        right: 0,
      }}
    >
      DEVELOPOMENT
      <button onClick={() => window.location.reload()} type="button">
        RESTART
      </button>
      <button
        onClick={() => window.ToppBonusToolboxJS.printTest()}
        type="button"
      >
        TEST PRINT
      </button>
      <button
        onClick={() => window.ToppBonusToolboxJS.showDevTools()}
        type="button"
      >
        DEV TOOLS
      </button>
    </div>
  );
};

function App() {
  return (
    <>
      {isDebug() && <DevTools />}
      <CssBaseline />
      <ThemeProvider theme={themeOptions}>
        <ReduxProvider store={store}>
          <HashRouter>
            <AppViewRouter />
          </HashRouter>
        </ReduxProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
