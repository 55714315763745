import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useHardwareFormFactor from "../../../hooks/useHardwareFormFactor";
import { Link } from "react-router-dom";
import { MENYVELGEREN_MODULES } from "../../../constants";
import { Badge, Typography } from "@mui/material";
import { ShoppingCartRounded, Edit, ArrowRight } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { getBasketCount } from "../../../redux/basketSlice";
import { useEffect, useRef, useState } from "react";
import {
  StyledCartButton,
  StyledCartInfo,
  StyledCartSummary,
  StyledFooter,
} from "./FooterStyle";
import {
  executeFooterNotification,
  selectFooterNotification,
} from "../../../redux/footerNotificationSlice";

const FooterNotification = ({ cartButtonRef }) => {
  const dispatch = useDispatch();
  const footerNotification = useSelector(selectFooterNotification);
  const [enabled, setEnabled] = useState(false);
  const [enabledStyle, setEnabledStyle] = useState({
    width: 100,
    bottom: -150,
  });

  const [disabledStyle, setDisabledStyle] = useState({
    width: 100,
    bottom: -150,
  });

  useEffect(() => {
    const newWidth = cartButtonRef.current
      ? cartButtonRef.current.offsetWidth
      : 150;

    const newHeight = cartButtonRef.current
      ? cartButtonRef.current.offsetHeight
      : 0;

    setEnabledStyle({
      width: `${newWidth - 50}px`,
      bottom: `${newHeight}px`,
    });

    setDisabledStyle({
      width: `${newWidth - 50}px`,
      bottom: -150,
    });
  }, [cartButtonRef]);

  useEffect(() => {
    if (footerNotification) {
      if (
        footerNotification.animateCounter !==
        footerNotification.lastExecutedCounter
      ) {
        dispatch(executeFooterNotification(footerNotification.animateCounter));
        setEnabled(true);
        setTimeout(() => {
          setEnabled(false);
        }, 2000);
      }
    }
  }, [dispatch, footerNotification]);

  const newProductTitle = footerNotification?.newProductAdded?.product?.title;
  const newProductQty = footerNotification?.newProductAdded?.qty;

  return (
    <StyledCartInfo style={enabled ? enabledStyle : disabledStyle}>
      <AddCircleOutlineIcon
        sx={{ fontSize: 40, margin: "18px", float: "left" }}
      />
      <Typography component="h5" variant="h5">
        Lagt til:
      </Typography>
      <p>
        {newProductQty}x {newProductTitle}
      </p>
    </StyledCartInfo>
  );
};

const BasketFooter = () => {
  const cartButtonRef = useRef(null);
  const { frameConfig } = useHardwareFormFactor();
  const { extendedFooterHeight, footerFontSize } = frameConfig;
  const count = useSelector(getBasketCount);

  return (
    <>
      <FooterNotification cartButtonRef={cartButtonRef} />
      <StyledFooter height={`${extendedFooterHeight}px`}>
        <StyledCartButton
          ref={cartButtonRef}
          variant="text"
          startIcon={<Edit />}
          fontSize={footerFontSize}
          component={Link}
          to={MENYVELGEREN_MODULES.BASKET()}
        >
          <Badge badgeContent={count} color="primary">
            Min bestilling
          </Badge>
        </StyledCartButton>
        <StyledCartSummary
          height={`${extendedFooterHeight}px`}
          fontSize={footerFontSize}
        ></StyledCartSummary>
        <StyledCartButton
          variant="text"
          fontSize={footerFontSize}
          startIcon={<ShoppingCartRounded />}
          endIcon={<ArrowRight />}
          component={Link}
          to={MENYVELGEREN_MODULES.CHECKOUT()}
        >
          Til kasse
        </StyledCartButton>
      </StyledFooter>
    </>
  );
};

export default BasketFooter;
