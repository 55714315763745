import { useEffect, useState } from "react";
import { SERVICES } from "../services";

const useMenyvelgerenAddons = (machineKey, pid) => {
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      if (machineKey && pid) {
        const r = await SERVICES.fetchAddonsData(machineKey, pid);
        const data = await r.json();
        setData(data);
      } else {
        setData(false);
      }
      setIsLoading(false);
    };

    loadData();
  }, [machineKey, pid]);

  return [data, isLoading];
};

export default useMenyvelgerenAddons;
