import { configureStore } from "@reduxjs/toolkit";
import footerNotificationSlice from "./footerNotificationSlice";
import machineKeySlice from "./machineKeySlice";
import processingOrderSlice from "./processingOrderSlice";
import menyvelgerenDataReducer from "./menyvelgerenDataSlice";
import storeDataSlice from "./storeDataSlice";
import basketSlice from "./basketSlice";

const MACHINE_KEY = "food4u-menyvelgeren-machine-key";

const loadState = () => {
  try {
    const data = JSON.parse(localStorage.getItem(MACHINE_KEY));
    if (data)
      return {
        machineKeyData: data,
      };
  } catch (e) {}
  return undefined;
};

export const store = configureStore({
  reducer: {
    machineKeyData: machineKeySlice,
    processingOrderData: processingOrderSlice,
    footerNotification: footerNotificationSlice,
    basketData: basketSlice,
    menyvelgerenData: menyvelgerenDataReducer,
    storeData: storeDataSlice,
  },
  preloadedState: loadState(),
});

store.subscribe(() => {
  const { machineKeyData } = store.getState();
  localStorage.setItem(MACHINE_KEY, JSON.stringify(machineKeyData));
});
