import React from "react";
import Basket from "../../components/common/Basket";
import Frame from "../../components/frame/Frame";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import {
  addToBasket,
  getBasket,
  getBasketCount,
  getEatIn,
} from "../../redux/basketSlice";
import { useDispatch, useSelector } from "react-redux";
import { MENYVELGEREN_MODULES, ENDPOINTS } from "../../constants";
import { ArrowRight } from "@mui/icons-material";
import {
  selectExtraOptions,
  selectStoreHasInPrice,
} from "../../redux/storeDataSlice";
import useOrderProcessor from "../../hooks/useOrderProcessor";
import CheckoutEmpty from "../../components/common/CheckoutEmpty";

const NavigationButtons = () => {
  const { processOrder } = useOrderProcessor();

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Grid item xs={6} sx={{ marginTop: "25px" }}>
        <Button
          component={Link}
          variant="contained"
          to={MENYVELGEREN_MODULES.MENU()}
          disableElevation
          sx={{ padding: "15px 45px" }}
        >
          Handle mer
        </Button>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right", marginTop: "25px" }}>
        <Button
          variant="contained"
          disableElevation
          color="success"
          sx={{ padding: "15px 45px" }}
          onClick={processOrder}
        >
          Bekreft bestilling
          <ArrowRight />
        </Button>
      </Grid>
    </Grid>
  );
};

const ExtraOptionsCard = ({ eatIn, extraOptions, addExtraOptions }) => {
  return (
    <Card>
      <CardMedia
        sx={{ height: 250 }}
        image={ENDPOINTS.image(extraOptions.picture)}
        title={extraOptions.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {extraOptions.title}
        </Typography>

        {extraOptions.price_in + extraOptions.price_out > 0 ? (
          <Typography variant="body2" color="text.secondary">
            {extraOptions.description}
            Pris: {eatIn ? extraOptions.price_in : extraOptions.price_out}
            ,-
          </Typography>
        ) : (
          ""
        )}
      </CardContent>
      <CardActions>
        <Button disableElevation variant="contained" onClick={addExtraOptions}>
          Ja, legg til
        </Button>
      </CardActions>
    </Card>
  );
};

const CheckoutView = () => {
  const dispatch = useDispatch();
  const basketCount = useSelector(getBasketCount);
  const hasInPrice = useSelector(selectStoreHasInPrice);
  const basket = useSelector(getBasket);
  const eatInRaw = useSelector(getEatIn);
  const eatIn = hasInPrice ? eatInRaw : false;
  const extraOptions = useSelector(selectExtraOptions);
  const includesFreeOption =
    basket?.filter((e) => e?.product?.isExtraOption).length > 0;

  const addExtraOptions = () => {
    dispatch(
      addToBasket({
        product: {
          ...extraOptions,
          isExtraOption: true,
          pid: 0,
        },
        addons: [],
        qty: 1,
      })
    );
  };

  const showExtraOptions = extraOptions && !includesFreeOption;

  if (basketCount === 0) {
    return (
      <Frame
        useCheckoutFooter={true}
        enableClickGuard={true}
        enablePadding={true}
      >
        <CheckoutEmpty />
      </Frame>
    );
  }

  if (showExtraOptions) {
    return (
      <Frame
        useCheckoutFooter={true}
        enableClickGuard={true}
        enablePadding={true}
      >
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Basket readonly={true} />
            <NavigationButtons />
          </Grid>
          <Grid item xs={5}>
            <ExtraOptionsCard
              eatIn={eatIn}
              extraOptions={extraOptions}
              addExtraOptions={addExtraOptions}
            />
          </Grid>
        </Grid>
      </Frame>
    );
  } else {
    return (
      <Frame
        useCheckoutFooter={true}
        enableClickGuard={true}
        enablePadding={true}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Basket readonly={true} />
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Frame>
    );
  }
};

export default CheckoutView;
