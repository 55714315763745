import useHardwareFormFactor from "../../../hooks/useHardwareFormFactor";
import { Link } from "react-router-dom";
import { ShoppingCartRounded, Edit, ArrowRight } from "@mui/icons-material";
import { MENYVELGEREN_MODULES } from "../../../constants";
import {
  StyledCartButton,
  StyledCartSummary,
  StyledFooter,
} from "./FooterStyle";
import useOrderProcessor from "../../../hooks/useOrderProcessor";

const CheckoutFooter = () => {
  const { processOrder } = useOrderProcessor();
  const { frameConfig } = useHardwareFormFactor();
  const { extendedFooterHeight, footerFontSize } = frameConfig;

  return (
    <StyledFooter height={`${extendedFooterHeight}px`}>
      <StyledCartButton
        variant="text"
        startIcon={<Edit />}
        fontSize={footerFontSize}
        component={Link}
        to={MENYVELGEREN_MODULES.MENU()}
      >
        Handle mer
      </StyledCartButton>
      <StyledCartSummary
        height={`${extendedFooterHeight}px`}
        fontSize={footerFontSize}
      ></StyledCartSummary>
      <StyledCartButton
        variant="text"
        fontSize={footerFontSize}
        background="#2e7d32"
        startIcon={<ShoppingCartRounded />}
        endIcon={<ArrowRight />}
        onClick={processOrder}
      >
        Bekreft bestilling
      </StyledCartButton>
    </StyledFooter>
  );
};

export default CheckoutFooter;
