import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  selectMenyvelgerenLoading,
  selectMenyvelgerenMenus,
  selectMenyvelgerenProducts,
} from "../../redux/menyvelgerenDataSlice";
import ItemList from "../../components/common/ItemList";
import Frame from "../../components/frame/Frame";
import { ENDPOINTS, MENYVELGEREN_MODULES } from "../../constants";

const ProductListView = () => {
  const navigate = useNavigate();
  const menuId = useParams().mid;
  const products = useSelector((s) => selectMenyvelgerenProducts(s, menuId));
  const menuData = useSelector(selectMenyvelgerenMenus);
  const isMenuLoadingLoading = useSelector(selectMenyvelgerenLoading);
  const productItems = (products || []).map((p) => ({
    ...p,
    linkTo: MENYVELGEREN_MODULES.PRODUCTPAGE(p.pid),
    image: ENDPOINTS.image(p.picture),
  }));

  const { menugroup } =
    (menuData ?? []).filter((e) => e.mid === menuId).pop() ?? {};

  return (
    <Frame
      useBasketFooter={true}
      enableClickGuard={true}
      enablePadding={true}
      loading={isMenuLoadingLoading}
    >
      <ItemList
        items={productItems}
        useImage={true}
        onPreviousPageClick={() =>
          menugroup
            ? navigate(MENYVELGEREN_MODULES.MENUGROUP(menugroup))
            : navigate(MENYVELGEREN_MODULES.MENU())
        }
      />
    </Frame>
  );
};

export default ProductListView;
