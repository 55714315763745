import { Button, Grid } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const MenyvelgerenPagination = ({
  page,
  pageCount,
  onChangePage,
  onPreviousPageClick,
  onNextPageClick,
}) => {
  const handleOnClick = (e, factor) => {
    const newPage = page + factor;
    if (newPage > 0 && newPage <= pageCount) {
      onChangePage(e, newPage);
    } else if (onNextPageClick && newPage > pageCount) {
      onNextPageClick(e);
    } else if (onPreviousPageClick && newPage <= 0) {
      onPreviousPageClick(e);
    }
  };

  const disablePrevButton =
    onPreviousPageClick === undefined ? page <= 1 : false;
  const disableNextButton =
    onNextPageClick === undefined ? page >= pageCount : false;
  const hiddenPrevButton = disablePrevButton ? pageCount === 1 : false;
  const hiddenNextButton = disableNextButton ? pageCount === 1 : false;
  const hiddenPageCounter = pageCount <= 1;

  if (pageCount === 1 && disableNextButton && disablePrevButton) return <></>;

  return (
    <Grid container direction="row" sx={{ marginTop: "25px" }}>
      <Grid item xs={5} sx={{ textAlign: "center" }}>
        <Button
          disabled={disablePrevButton}
          color={disablePrevButton ? "primary" : "secondary"}
          variant={disablePrevButton ? "contained" : "contained"}
          disableElevation
          sx={{ padding: "15px 65px", opacity: hiddenPrevButton ? 0 : 1 }}
          onClick={(e) => handleOnClick(e, -1)}
        >
          <NavigateBeforeIcon />
          Tilbake
        </Button>
      </Grid>
      <Grid item xs={2} sx={{ textAlign: "center" }}>
        <Button
          color="primary"
          variant="text"
          disableElevation
          sx={{ padding: "15px 5px", opacity: hiddenPageCounter ? 0 : 1 }}
        >
          Side {page} av {pageCount}
        </Button>
      </Grid>
      <Grid item xs={5} sx={{ textAlign: "center" }}>
        <Button
          disabled={disableNextButton}
          color={disableNextButton ? "primary" : "secondary"}
          variant={disableNextButton ? "text" : "contained"}
          disableElevation
          sx={{
            padding: "15px 65px",
            opacity: hiddenNextButton ? 0 : 1,
          }}
          onClick={(e) => handleOnClick(e, +1)}
        >
          Neste
          <NavigateNextIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default MenyvelgerenPagination;
