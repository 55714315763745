import { APPPROFILE } from "../../constants";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import useHardwareFormFactor from "../../hooks/useHardwareFormFactor";
import { useNavigate } from "react-router-dom";

const ImageButton = styled(ButtonBase)(({ theme, height }) => ({
  height: height,
  width: "100%",
  border: `1px solid ${APPPROFILE.PROFILECOLOR}`,
  borderRadius: "15px",
  boxShadow: "0px 0px 3px 3px rgba(0,0,0,0.2)",
  overflow: "hidden",
}));

const GridItemIcon = styled("div")(({ theme }) => ({
  width: 125,
  height: 125,
  svg: {
    width: 125,
    height: 125,
  },
}));

const GridItemImage = styled("div")(({ theme, image }) => ({
  width: "100%",
  height: 190,
  marginTop: -24,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundImage: `url(${image})`,
}));

const GridItemImageContain = styled("div")(({ theme, image }) => ({
  width: "100%",
  height: 124,
  marginTop: 25,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundImage: `url(${image})`,
}));

export default function ItemGridButton({
  linkTo,
  text,
  icon,
  image,
  useImageContain,
  useImage,
}) {
  const navigate = useNavigate();
  const { gridConfig } = useHardwareFormFactor();
  return (
    <Box
      sx={{ display: "flex", flexWrap: "wrap", minWidth: 300, width: "100%" }}
    >
      <ImageButton
        focusRipple
        onClick={() => setTimeout(() => navigate(linkTo), 100)}
        height={gridConfig.itemHeight}
      >
        <Grid
          container
          rowSpacing={3}
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          {useImage && !useImageContain && <GridItemImage image={image} />}
          {useImage && useImageContain && (
            <GridItemImageContain image={image} />
          )}
          {!useImage && (
            <Grid item>
              <GridItemIcon>{icon}</GridItemIcon>
            </Grid>
          )}
          <Grid item>
            <Typography>{text}</Typography>
          </Grid>
        </Grid>
      </ImageButton>
    </Box>
  );
}
