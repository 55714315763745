import { Grid } from "@mui/material";
import { useState } from "react";
import useHardwareFormFactor from "../../hooks/useHardwareFormFactor";
import ItemGridButton from "./ItemGridButton";
import MenyvelgerenPagination from "./MenyvelgerenPagination";

const ItemGrid = ({ items, onNextPageClick, onPreviousPageClick }) => {
  const [page, setPage] = useState(1);
  const { gridConfig } = useHardwareFormFactor();
  const pageItemStart = gridConfig.itemGridMaxItemsPrPage * (page - 1);
  const totalPageCount = Math.ceil(
    (items || []).length / gridConfig.itemGridMaxItemsPrPage
  );
  const visibleItems = [...(items || [])].slice(
    pageItemStart,
    pageItemStart + gridConfig.itemGridMaxItemsPrPage
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: "100%" }}
    >
      <Grid item sx={{ width: "100%" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          rowSpacing={2}
          columnSpacing={2}
        >
          {visibleItems.map(
            ({ linkTo, text, icon, image, useImage, useImageContain }, idx) => (
              <Grid item xs={4} key={`${text}_${idx}`}>
                <ItemGridButton
                  linkTo={linkTo}
                  text={text}
                  icon={icon}
                  image={image}
                  useImage={useImage}
                  useImageContain={useImageContain}
                />
              </Grid>
            )
          )}
        </Grid>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <MenyvelgerenPagination
          pageCount={totalPageCount}
          page={page}
          onChangePage={(e, page) => setPage(page)}
          onNextPageClick={onNextPageClick}
          onPreviousPageClick={onPreviousPageClick}
        />
      </Grid>
    </Grid>
  );
};

export default ItemGrid;
