import { CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import Frame from "../../components/frame/Frame";

const LoadingView = ({ noFrame }) => {
  const children = (
    <>
      <Grid
        item
        xs={2}
        sx={{
          textAlign: "center",
        }}
      >
        <CircularProgress
          color="secondary"
          size={100}
          sx={{ marginBottom: "50px" }}
        />
        <Typography variant="h4">Vent litt</Typography>
      </Grid>
    </>
  );

  if (noFrame)
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100vh",
        }}
      >
        {children}
      </Grid>
    );

  return (
    <Frame enablePadding={true} useBasketFooter={false}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        {children}
      </Grid>
    </Frame>
  );
};

export default LoadingView;
