import Button from "@mui/material/Button";
import QtyField from "./QtyField";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { APPPROFILE } from "../../constants";
import { Grid } from "@mui/material";
import useHardwareFormFactor from "../../hooks/useHardwareFormFactor";

export const AddToBasketFooter = ({
  qty,
  setQty,
  onPreviousPageClick,
  handleAddProductToBasket,
}) => {
  const { gridConfig } = useHardwareFormFactor();

  return (
    <Grid
      item
      sx={{
        borderTop: `5px solid ${APPPROFILE.ACCENTCOLOR}`,
        width: "100%",
        marginTop: `${gridConfig?.addToBasketButtonMarginTop || 20}px`,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: `${gridConfig?.addToBasketButtonHeight || 150}px`,
        }}
      >
        <Grid item xs sx={{ padding: "15px" }}>
          {onPreviousPageClick && (
            <Button
              color="secondary"
              variant="contained"
              disableElevation
              sx={{
                width: "75%",
                padding: "20px",
              }}
              onClick={onPreviousPageClick}
            >
              <NavigateBeforeIcon />
              Tilbake
            </Button>
          )}
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item sx={{ paddingRight: "25px" }}>
              <QtyField value={qty} onChange={(qty) => setQty(qty)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs sx={{ padding: "15px" }}>
          <Button
            onClick={handleAddProductToBasket}
            disableElevation
            color="success"
            variant="contained"
            sx={{
              width: "100%",
              padding: "20px",
            }}
          >
            Legg til i handlekurv
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
