import { Alert, AlertTitle, Backdrop } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  SCREEN_TIMEOUT,
  SCREEN_TIMEOUT_COUNTDOWN,
  SCREEN_TIMEOUT_ENABLED,
} from "../../constants";

const StyledAlert = styled(Alert)({
  ".MuiAlert-icon": { fontSize: "3em" },
  fontSize: "1.2em",
});

const ClickGuard = ({ children, enable }) => {
  const enableClickGuard = SCREEN_TIMEOUT_ENABLED && enable;
  const [warningOpen, setWarningOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const [lastClick, setLastClick] = useState(new Date().getTime());

  const handleClose = () => {
    setWarningOpen(false);
  };

  useEffect(() => {
    if (enableClickGuard) {
      const interval = setInterval(() => {
        setWarningOpen(true);
      }, SCREEN_TIMEOUT * 1000);
      return () => clearInterval(interval);
    }
  }, [enableClickGuard, lastClick]);

  useEffect(() => {
    if (warningOpen) {
      let localCounter = SCREEN_TIMEOUT_COUNTDOWN;
      setCounter(localCounter);

      const interval = setInterval(() => {
        localCounter--;
        if (localCounter > 0) {
          setCounter(localCounter);
        } else {
          window.location = "/";
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [warningOpen]);

  return (
    <div onClick={() => setLastClick(new Date().getTime())}>
      {children}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={warningOpen}
        onClick={handleClose}
      >
        <StyledAlert severity="info" sx={{ padding: 7 }}>
          <AlertTitle sx={{ fontSize: "2em" }}>Avslutter</AlertTitle>
          <p>Din ordre er i ferd med å bli avsluttet på grunn av inaktivitet</p>

          <p>
            Avslutter om: <span id="countdown-timer">{counter}</span> sek
          </p>

          <p>Trykk på skjermen for å fortsette bestillingen</p>
        </StyledAlert>
      </Backdrop>
    </div>
  );
};

export default ClickGuard;
