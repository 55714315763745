const useHardwareFormFactor = () => {
  const { innerWidth: width, innerHeight: height } = window;

  const isNewFormFactor = height > 1300;
  let gridConfig;
  let screensaverConfig;
  let printscreenConfig;
  let frameConfig;
  if (isNewFormFactor) {
    gridConfig = {
      itemGridMaxItemsPrPage: 15,
      itemListMaxItemsPrPage: 16,
      addonsItemShowTitle: true,
      addonsItemPrPage: 15,
      addToBasketButtonHeight: 150,
      addToBasketButtonMarginTop: 30,
      itemHeight: 250,
      itemBarItems: 6,
    };

    frameConfig = {
      footerFontSize: "1.5em",
      framePadding: "25px",
      headerHeight: 80,
      defaultFooterHeight: 35,
      extendedFooterHeight: 105,
    };

    screensaverConfig = {
      welcomeMessageHeight: "400px",
      pictureHeight: "800px",
    };

    printscreenConfig = {
      showDownArrows: true,
    };
  } else {
    gridConfig = {
      itemGridMaxItemsPrPage: 9,
      itemListMaxItemsPrPage: 9,
      addonsItemShowTitle: false,
      addonsItemPrPage: 8,
      addToBasketButtonHeight: 110,
      addToBasketButtonMarginTop: 15,
      itemHeight: 200,
      itemBarItems: 6,
    };

    frameConfig = {
      footerFontSize: "1.1em",
      framePadding: "25px",
      headerHeight: 57,
      defaultFooterHeight: 35,
      extendedFooterHeight: 70,
    };

    screensaverConfig = {
      welcomeMessageHeight: "100px",
      pictureHeight: "480px",
    };

    printscreenConfig = {
      showDownArrows: false,
    };
  }

  frameConfig.frameContentMaxHeight = `calc(100vh - ${
    frameConfig.headerHeight + frameConfig.footerHeight
  }px)`;

  return {
    isNewFormFactor,
    gridConfig,
    frameConfig,
    screensaverConfig,
    printscreenConfig,
    isVertical: width < height,
    windowDimensions: {
      width,
      height,
    },
  };
};

export default useHardwareFormFactor;
