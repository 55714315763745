import { APPPROFILE } from "../../../constants";
import { Box } from "@mui/material";
import useHardwareFormFactor from "../../../hooks/useHardwareFormFactor";

const Footer = () => {
  const { frameConfig } = useHardwareFormFactor();
  const { defaultFooterHeight } = frameConfig;

  return (
    <Box
      sx={{
        borderTop: `5px solid ${APPPROFILE.ACCENTCOLOR}`,
        padding: 0,
        margin: 0,
      }}
    >
      <Box
        sx={{
          background: APPPROFILE.PROFILECOLOR,
          padding: `${defaultFooterHeight - 5}px 0px 0px 0px`,
        }}
      ></Box>
    </Box>
  );
};

export default Footer;
